const messages = {
    seeManual: 'Alternatively, you can learn how to use the system from the menu.',
    incaseHasLineOA: 'If you already have a Line OA (Official Account) for your shop, you can register to use the system',
    enablePointDialog: 'You have enabled point accumulation settings. Please enter the information and save again to confirm the activation',
    settingPointDialogWarning: 'If you change the point accumulation settings, all point accumulation transactions will affect every bill immediately upon changing the settings',
    confirmToSuspenPointAccomulate: 'Do you want to disable point accumulation?',
    suspenPointDialog: 'If you disable point accumulation, all point accumulation transactions will affect every bill immediately upon changing the settings',
    confirmUpdateUserInfo: 'Confirm to save info?',
    mamberNotFound: 'Member not found',
    confirmToSaveParicipateItemTitle: 'Confirm to Save Paricipate Item',
    vilidCouponName: 'Please Enter Coupon Name',
    couponStatus: 'Coupon Status',
    couponCode: 'Coupon Code',
    unlimited: 'Unlimited',
    deleteCouponDialog: 'In the event that a coupon is deleted, it cannot be recovered. A new coupon must be created',
    deleteCouponConfirmation: 'Do you want to delete this coupon?',
    couponUsageDetail: 'Coupon Usage Detail',
    deleteCoupon: 'Delete Coupon',
    remainingCoupon: 'Remaining Coupon',
    notEnoughPoint: 'The member\'s points are insufficient',
    validSelectMember: 'Please Select Member',
    validPointInput: 'Incorrect Point Input',
    selectProductForAdjustStock: 'Select items to adjust stock',
    nolimitTimeOrUntilNoStock: 'Not limited by time or until the product runs out',
    points: 'Point',
    enterDetail: 'Enter Detail',
    validEnterDetail: 'Please Enter Detail',
    confirmToSaveParicipateItem: 'Do you confirm to save participate item',
    confirmItem: 'Confirm Item',
    value: 'Value',
    createCouponSuccess: 'Create Coupon Success',
    couponAmountValidate: 'Please enter at least 1 coupon',
    limit: 'Limit',
    noLimitTime: 'No Limit Time',
    couponPeriod: 'Coupon Redemption Period',
    usePoint: 'Use Point',
    showCouponToStaff: '1. Show coupons to staff',
    specialRightsNote: '2. This coupon cannot be exchanged, redeemed, or converted to cash.',
    reservationRights: '3. We reserve the right to modify or change without prior notice.',
    couponUsageCondition: 'Coupon Usage Condition',
    showCouponCondition: 'Display default coupon usage conditions',
    couponAmount: 'Coupon Amount',
    couponPerMember: 'Coupon/Member',
    limitPerPerson: 'Limit Per Person',
    redeemPerMenber: 'Redemption per member',
    couponAmountCondition: 'Coupon Condition',
    couponExpiration: 'Coupon Expiration',
    couponExpiry: 'Coupon expiration after reciving',
    specifyCouponPeriod: 'Set the duration of coupon usage',
    couponValuePlacehoolder: 'Enter Coupon Value ...',
    couponValue: 'Coupon Value',
    amountPointPlaceholder: 'Enter points for redeeming rewards ...',
    amountPoint: 'Amount of points for redemption',
    couponName: 'Coupon Name',
    redeemItem: 'Redeem Item Coupon',
    discountCoupon: 'Discount Coupon',
    selectCouponType: 'Select Coupon Type',
    registerLineOA: "Register Line OA",
    registerStore: "Register Store",
    setLoyaltyPoints: "Set Loyalty Points",
    createRewardCoupons: "Create Reward Coupons",
    inputCouponName: 'Enter Coupon Name',
    validInputCouponName: 'Please Enter Coupon Name',
    createEVoucher: "Create E-Voucher",
    createPreVoucher: "Create Pre-Voucher",
    importRetrieveLoyaltyPoints: "Import/Retrieve Loyalty Points",
    setRewardPointCards: "Set Reward Point Cards",
    setAutoNotifications: "Set Auto Notifications",
    productMenu: "Product Menu",
    home: 'Home',
    fullName: 'Full Name',
    posterDialog: 'To register as a member, accumulate points, and redeem rewards',
    scan: 'Scan',
    justAddFriend: 'Just add friend',
    posterExample: 'Poster Example',
    posterExampleDialog: 'Join as a member, accumulate points, and easily redeem rewards',
    downloadPoster: 'Download Poster',
    contactInformation: 'Contact',
    addEditContactAtSetting: 'Add/Edit contact information for the store from the settings menu',
    addContactInformation: 'Add Contact',
    editTextDialog: 'Edit Display Text on Poster',
    editText: 'Edit Text',
    uploadQR: 'Upload QR Code',
    QRAbleToDownload: 'The QR code image for the store\'s Line OA can be downloaded',
    uploadQRCodeFile: 'Upload QR Code',
    validInputContact: 'Please enter at least 1 information before saving',
    website: 'Website',
    inputPhoneNumber: 'Input Phone Number',
    contactDialog: 'Manage store contact information',
    activeNotifyDialog: 'You have enabled automatic notifications. Please save the information to confirm activation',
    confirmToDisableNotify: 'Do you want to turn off automatic notifications?',
    inputAccessTokenPlaceholder: 'Please enter the Access Token obtained from Line Notify',
    manualGenerateToken: 'You can view the Access Token issuance guide here',
    lineNotifyDialog: 'Stay informed about all service activities and point transactions with automatic notifications via Line Notify',
    amountMoney: 'Amount of Money',
    inputMoneyAmount: 'Input Payment Amount',
    settingRateLoyaltyPotint: 'Setting the rate of converting loyalty points from the amount per point',
    shopDoNotSetFormmatDialog: 'The system will calculate in the format of rounding down. For example, if the rate is 10 baht for 1 point, and a customer purchases items worth 15 baht, they will receive 1 loyalty point',
    shopDoNotSetFormmat: 'In case the store has not specified a format',
    calculationFormat: 'Calculation format',
    somethingWentWrong: 'Something went wrong. Please check the information',
    saveLoyaltyPointSettingSuccess: 'The loyalty points configuration has been successfully saved',
    setConversionRate: 'Set conversion rate for loyalty points',
    activeMembershipWithSilom: 'You have activated the membership system with Silom POS. Please enter the information and save again to confirm the activation',
    confirmToDisableCrm: 'Do you want to deactivate the membership system?',
    confirmToDisableCrmDialog: 'If you deactivate the membership system,transactions related to the membership system will affect all invoices starting from the immediate configuration change',
    confirmSaveData: 'Are you confirm to save this data?',
    clickHere: 'Click Here',
    shopCanSetUpLoyatyPointAtMenuSetting: 'The store can set up point accumulation in the Settings tab -> Loyalty Points Setting',
    checkingData: 'Checking Data',
    setupInformation: 'Edit',
    orSeeDetailsAtMenu: 'or see details in the menu',
    inCaseNoLineOA: 'In case the store does not have an official Line account yet, you can register at',
    businessPoster: 'Business Poster',
    businessPosterDialog: 'Print the poster and place it at the store front to encourage customers to add us on LINE. This will make it easier for your customers to access the store',
    setUpAutoNotify: 'Set Up Automatic Notifications',
    setUpAutoNotifyDialog: 'Setting up automatic notifications via Line Notify',
    settingRoyaltyPoint: 'Royalty Points Setting',
    shopRegister: 'Shop Register',
    contactCustomerService: 'Contact Customer Service',
    noPaymentDetail: 'No Payment Details',
    expired: 'Expired',
    voucherCode: 'Voucher Code',
    additionalDetail: 'Additional Details',
    index: 'Index',
    paidBy: 'Paid By',
    searchBy: 'Search By',
    editAt: 'Edit At',
    duration: 'Duration',
    unspecified: 'Unspecified',
    voucherAmount: 'Voucher Amount',
    printCount: 'Print Count',
    editHistory: 'Edit History',
    extendAllVoucher: 'All vouchers in the list will have their usage period extended',
    noLimitTimeofUse: 'No time limit for usage',
    cancelThisVoucher: 'Cancel This Voucher',
    cancelThisVoucherDialog: 'Do you want to cancel this voucher?',
    cancelThisVoucherDialog2: 'In the event that the voucher is canceled, it cannot be retrieved, and all vouchers in this set will no longer be usable',
    extendDuration: 'Extend the duration of use',
    printVoucher: 'Print This Voucher Page',
    voucherIsCanceled: 'Voucher is Canceled',
    voucherIsDeleted: 'Voucher is deleted',
    sent: 'Sent',
    createVoucherCompleted: 'Create Voucher Completed',
    example: 'Example',
    startingNumber: 'Starting Number',
    startingNumberPlaceholder: 'Input Starting Number, ex. 1 ...',
    code: 'Code',
    codePlaceholder: 'Specify the initial letters, ex. VOU ...',
    incaseNoCodeDialog: 'In case you do not want to specify any letters, leave it blank',
    custom: 'Custom',
    createBySystemDefault: 'Create By System',
    creationOption: 'Creation Option',
    incaseNoUsagePeriod: 'In case no usage period is specified, leave it blank',
    limit1000Voucher: 'Maximum limit of 1,000 voucher',
    voucherAmount: 'Voucher Amount',
    inputVoucherValue: 'Input Voucher Value',
    inputVoucherDisplayName: 'Input Voucher Display Name',
    generalDetail: 'General details',
    create: 'Create',
    noExpiryDate: 'Not specifying an expiration',
    expiryDate: 'Expiry Date',
    voucherNumber: 'Voucher No.',
    receivedDate: 'Received Date',
    couponReceivedDate: 'Received Date',
    couponUsedDate: 'Used Date',
    wantToCancelVoucher: 'Do you want to cancel this voucher?',
    voucherCanNotBeRecovered: 'In case the voucher is canceled, it cannot be recovered',
    cancelAllVoucher: 'Cancel All Voucher',
    cancelVoucher: 'Cancel Voucher',
    createNewCard: 'Create New Card',
    suspenCardSucces: 'The loyalty card has been successfully suspended',
    pleaseApcceptTerm: 'Please accept the terms and conditions',
    pleaseSelectSuspenPeriod: 'Please select the correct card suspension date and the end date of point distribution',
    settingCompleted: 'Setting Completed',
    selectProductUnit: 'Please Select Product Unit',
    inputProductAmount: 'Please Input Product Amount',
    errorAlert: 'There seems to be some errors. Please double-check the information!',
    completely: 'Complete',
    sendVoucherComplete: 'Sent Voucher to',
    validateInput: 'Please fill in all the required information',
    validDateInput: 'Please select a valid duration',
    here: 'Here',
    haveNoVoucher: 'You don\'t have any voucher. You can create a voucher',
    confirmToSend: 'Confirm to send',
    confirmToSendTo: 'to',
    condition: 'Note / Additional Terms and Conditions',
    attachFile: 'Attach File',
    attachFileDialog: 'Attach payment receipt ...',
    additionalNote: 'Additional Note',
    additionalNoteDialog: 'Additional information, such as account number or payment date',
    inputPaymentAmount: 'Input payment amount',
    paymentAmount: 'Payment Amount',
    customPaymentMethod: 'Please specify payment method',
    paymentMethod: 'Payment Method',
    paymentMethodDialog: 'Select Payment Method',
    selectMemberBySearch: 'Select members can be searched by name, phone number, or email',
    startFrom: 'Available for use starting from',
    periodAfterRecivedVoucher: 'Expiry after receiving voucher',
    selectVoucher: 'Select Voucher',
    createEVoucher: 'Create Electronic Voucher ( E-Voucher )',
    sendEVoucher: 'Send Electronic Voucher ( E-Voucher )',
    createEVoucher2: 'Which customers can utilize through the store\'s Line OA',
    preview: 'Preview',
    setPeriod: 'Set Period',
    voucherExpire: 'Set Voucher Expiry Period',
    setPeriodAfterRecivcedVoucher: 'Set Expiry Date After Receiving Voucher',
    voucherValue: 'Voucher Value',
    voucherDetailPlaceholder: 'Enter details explaining the voucher, such as terms and conditions of use ...',
    voucherNamePlaceholder: 'Enter Voucher Name ...',
    voucherName: 'Voucher Name',
    createVoucher: 'Create Voucher',
    eVoucherUsage: 'E-VOUCHER Usage',
    preVoucherUsage: 'PRE-VOUCHER Usage',
    eVoucher: 'E-VOUCHER',
    allVoucher: 'ALL VOUCHER',
    sendVoucherHistory: 'Voucher Sending Transaction',
    sendVoucherHistoryDialog: 'The transaction of sending electronic vouchers (E-Vouchers) can be searched by code or member\'s name, along with displaying usage status',
    sendVoucher: 'Send Voucher',
    preVoucher: 'Pre-Voucher',
    preVoucherDialog: 'Creating Pre-Voucher in the form of printed voucher sets and distributing them to members afterwards',
    createPreVoucher: 'Create Pre-Voucher',
    haveNotActivateCard: 'You have not activated the rewards card yet',
    haveNotActivateCardDialog: 'You can configure and activate the card in the menu bar Settings > Loyalty Card Settings or learn more about system usage from',
    searchMemberByName: 'Search By Name...',
    loyaltyPointMember: 'Loyalty Points Member',
    loyaltyPointMemberDialog: 'Display a list of members who have loyalty points and allow searching by name',
    currentPoint: 'Current Points',
    redeemTime: 'Redeem Rewards (time)',
    info01: 'The store will need to fill in all the information for the new creation',
    inCaseCreateNewCard: 'In case of creating a new loyalty card, the system will delete the old loyalty card',
    viewDetail: 'View Detail',
    participateItems: 'The participating items',
    createNewCard: 'Create New Card',
    suspendCardSpacified: 'The loyalty card will be suspended according to this schedule',
    usageStatus: 'Usage Status',
    suspenCard: 'Suspend',
    consent: 'I accept term and conditions of service',
    noteCardSuspension: 'You will not be able to edit or cancel the card suspension date. All related settings and data will be reset, including points distributed to customers. You will not be able to reactivate the suspended card for 1 day after suspension',
    pleaseUseCard: ' ,Please use the card before the specified date. However, You still be able to accumulate points until ',
    validUntil: 'valid until ',
    cardFromShop: 'Loyalty Card from',
    alertMessage: 'Alert Message',
    cardSuspension: 'Loyalty Card Suspension',
    cardSuspensionDate: 'Card suspension date',
    endDateOfPoint: 'End date of point distribution',
    saveCardSettingDialog: 'Do you want to save the loyalty card settings and activate the card?',
    saveDraftConfirmation: 'Do you want to save this draft?',
    saveAndActive: 'Save and Active',
    updateAndActive: 'Update and Active',
    saveDraft: 'Save Draft',
    youSelected: 'You selected',
    selectProductForPointAccumulation: 'Select items for point accumulation',
    getFree: 'Get free',
    inputFreeItem: 'Input amount of free item...',
    purchaseAmount: 'Purchase items amount',
    suspendUsage: 'Suspend Usage',
    systemUserManual: 'System user manual',
    orCheckOutAt: 'or learn more about system usage from',
    youCanRegisterAt: 'You can register at',
    noRegisterLineOA: 'You have not registered your store (LINE OA)',
    cardSetting: 'Loyalty Card Setting',
    cardSettingDialog: 'Set terms and conditions for using the loyalty card',
    createCardDialog: 'You can create a loyalty card at ',
    noCard: 'No loyalty card information available',
    availableCrm: 'Available',
    used: 'Used',
    unused: 'Not used yet',
    redeemRewards: 'Redeem rewards',
    noteCrm: 'Note',
    noteCardDialog1: "Customers will earn points from purchasing participating items within the store.",
    noteCardDialog2: "Cannot be exchanged for cash.",
    noteCardDialog3: "Cannot be used in conjunction with reward cards, discounts, or others.",
    noteCardDialog4: "Cards with 'Used' status cannot be redeemed again.",
    noteCardDialog5: "Rights can be claimed by tapping on the loyalty card and confirming the redemption.",
    redeemFor: 'redeem for',
    accomulateComplete: 'Accumulate',
    exampleOfRewardCard: 'Example of a loyalty card for customers',
    draft: 'Draft',
    from: 'from',
    reward: 'Rewards',
    createReward: 'Create rewards for customers to redeem vouchers here',
    createCoupon: 'Create Reward Coupons',
    deletedCoupon: 'Coupon deleted from the system',
    retrieveLoyaltyPoints: 'Retrieve Loyalty Points',
    retrieveLoyaltyPointsDialog: 'Search for members and retrieve them loyalty points',
    sendLoyaltyPoints: 'Send Loyalty Points',
    sendLoyaltyPointsDialog: 'Search for members and send them loyalty points',
    searchMemberDialog: 'Search for members by name or phone number',
    searchMemberCRM: 'Search Member',
    pointAmount: 'Point Amount',
    sendPoint: 'Send Loyalty Points',
    youWantToAdd: 'Do you want to send',
    youWantToRetrive: 'Do you want to retrive',
    toCrm: 'to',
    nameCrm: 'Name',
    inputPoint: 'Input Number of Points',
    rewardFromPointsAccumulation: 'Rewards from Loyalty Points',
    transactionStatus: 'Transaction Status',
    pointRecive: 'Point Received',
    amount: 'Amount',
    today: 'Today',
    days: 'Day',
    yesterday: 'Yesterday',
    last7days: 'Last 7 Days',
    last30days: 'Last 30 Days',
    thismonth: 'This Month',
    lastmonth: 'Last Month',
    transection: 'Transaction',
    comingSoon: 'Coming Soon',
    loyaltyCard: 'Loyalty Card',
    welcomeMessage: 'Welcome to',
    messageSent: 'Message is sent to staff',
    outOfStock: 'Out of Stock',
    QRCode: 'QR Code',
    GenerateQRCode: 'Generate QR Code',
    ForQROrder: 'For ordering food and asking for in-store service',
    QROrderDescription: 'You can generate a QR code for customers to scan for ordering food and asking for in-store service',
    SelectTable: 'Choose a table and input number of guests.',
    shopname: 'Shop Name',
    numberOfCustomer: 'Number of customers (people)',
    numberOfCustomerDescription: 'Input the number of customers, minimum 1 person',
    currentUser: 'User',
    guest: 'Guest',
    orderItem: 'Ordered Item',
    callStaff: 'Call Staff',
    callStaffDialog: 'What kind of assistance do you need?',
    cutlery: 'Request for cutlery',
    seasoning: 'Request for seasoning',
    refillDrink: 'Refill drink',
    other: 'Other',
    tableNumber: 'Table',
    menuList: 'Menu',
    selectedItem: 'Selected Items',
    startOrder: 'Start Order',
    foodCategory: 'Category',
    searchByName: 'Search Product By Name...',
    noPreduct: 'No Product available',
    noSelectedProduct: 'No Selected Product',
    backToMenu: 'Back to Menu',
    removeSelected: 'Do you want to remove this item?',
    confirmOrder: 'Confirm Order',
    endOfTransection: 'End of transaction',
    endOfTransectionDialog: 'In case of usage issues, please contact the staff',
    note: 'Note',
    orderTime: 'Order time',
    orderMoreFood: 'Back to Menu',
    login: 'Login',
    forgetPassword: 'Forget Password',
    loginWithGmail: 'Login With Gmail',
    or: 'or',
    loginAsStaff: 'Login As Staff',
    billOrder: 'Bill Order',
    table: 'Table',
    billOrderDialog: 'Scan the QR code to view the menu and place an order',
    billOrderDialog2: 'In case of usage issues, please contact the staff',
    selectTableAleart: 'Please select a table before generating the QR code',
    emptyCartDialog: 'No Transaction',
    served: 'Served',
    contactOurStaff: 'Contact Our Staff',
    contactOurStaff2: 'In case asking for help',
    addToCart: 'Add to Cart',
    noteToShop: 'Note to shop',
    addYourRequest: 'Add your request',
    branchName: 'Branch Name',
    businessType: 'Business Type',
    taxID: 'Tax ID',
    vat: 'Tax',
    exclusive: 'Exclusive',
    inclusive: 'Inclusive',
    serviceCharge: 'Service Charge',
    address: 'Address',
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    tel: 'Tel',
    businessType1: 'General',
    businessType2: 'Restaurant',
    businessType3: 'Hostel',
    shopopen: 'Open - Closed',
    open: 'Open',
    closed: 'Closed',
    languagetxt: 'Language',
    language: 'Language & Timezone',
    languageTH: 'Thai',
    languageEN: 'English',
    setting: 'Settings',
    shop: 'Shop',
    timezone: 'Timezone',
    deliveryProviders: 'Delivery Providers',
    adddeliveryProviders: 'Add Providers',
    lastUpdate: 'Last update',
    logo: 'Logo',
    updateData: 'Update data',
    submit: 'Submit',
    cancel: 'Cancel',
    normal: 'Normal',
    loading: 'loading...',
    editing: 'Editing',
    confirmUpdate: 'Do you want to updated data?',
    selectedShoptype: 'Selected Business Type...',
    selectshop: 'Select Branch Name',
    save: 'Save',
    reporttxt: 'Reports',
    general: 'General',
    signOut: 'Sign Out',
    registration: '',
    unit: 'Unit',
    category: 'Category',
    product: 'Product',
    export: 'Export',
    exportKBank: 'Export K-Bank',
    date: 'Date',
    to: 'to',
    status: 'Status',
    vatAmount: 'Tax',
    netTotalNonVAT: 'Total (non-Tax)',
    receiptNumber: 'Receipt Number',
    grandTotal: 'Grand Total',
    netAmountVATTotal: 'Sub-Total(ex-Tax)',
    netTotalIncVAT: 'Total (incl-Tax)',
    proofOfPayment: 'Proof of payment',
    paymentType: 'Payment Type',
    paymentType0: 'Cash',
    paymentType1: 'Credit card',
    paymentType2: 'Coupon',
    paymentType3: 'Quickpay',
    paymentType4: 'Promptpay',
    paymentType5: 'Alipay',
    paymentType6: 'Deposit',
    paymentType7: 'Delivery',
    paymentType8: 'Online',
    paymentType9: 'Line Pay',
    paymentType10: 'Custom Pay',
    paymentType12: 'KBank',
    paid: 'Payment value',
    change: 'Change',
    sentSuccessfully: 'Sent successfully',
    pluCode: 'Barcode',
    netAmount: 'Sub Total',
    discount: 'Discount',
    timeOfOrder: 'Time of order',
    orderDate: 'Order Date',
    preparing: 'Preparing',
    paymentDetail: 'Payment Detail',
    orderNumber: 'Order Number',
    delivered: 'Due date',
    deliveredDate: 'Lead time',
    payment: 'Payment',
    productName: 'Product Name',
    quantity: 'Quantity',
    priceUnit: ' Price / Unit',
    baht: 'Baht',
    profit: 'Profit',
    sales: 'Sales',
    saleAmount: 'Sales',
    billAmount: 'Bill total',
    total: 'Total',
    bill: 'Bill',
    discountAmount: 'Discount Amount',
    averageBill: 'Average / bill',
    voidbillTotal: 'Voidbill Total',
    salesByDaily: 'Sales By Daily',
    salesByMonth: 'Sales By Month',
    bestSellers: 'Best sellers',
    bestSellerstype: 'Best sellers category',
    salesBydate: 'Sales by date',
    number: 'No.',
    categoryName: 'Category Name',
    newOrder: 'New Order',
    reject: 'Reject',
    inProgressTime: 'In Progress time',
    rejectTime: 'Reject Time',
    cancelTime: 'Cancel Time',
    cause: 'Cause',
    preparingToShip: 'Preparing',
    totalAmount: 'Total',
    order: 'Order',
    deposit: 'Deposit',
    billSave: 'Save',
    ReceivingOrder: 'Open table/Receiving order',
    orderNo: 'Order No.',
    remark: 'Remark',
    receiptStatus: 'Receipt Status',
    back: 'Back',
    detail: 'Detail',
    countSales: 'Quantity',
    onhandQty: 'Balance',
    stdCost: 'Cost',
    employees: 'Employees',
    employeesname: 'Employees Name',
    salesTotal: 'Sales Total',
    totalBill: 'All bill count',
    billActive: 'Selling bills count',
    billVoice: 'Cancel bills count',
    billDeposit: 'Deposit bills count',
    discountItem: 'Total item discount',
    billDiscountAmount: 'Total bill discount',
    billDiscount: 'Billing discount count',
    payTotal: 'Payment Total',
    deliveryProvider: 'Delivery Provider',
    deliveryDetail: 'Delivery Detail',
    customDetail: 'Custom payment Detail',
    customPaymentName: 'Custom Payment Name',
    firstInvoiceNO: 'First bill no.',
    lastInvoiceNO: 'Last bill no.',
    roundNo: 'Round No.',
    closeAt: 'Close At',
    closeBy: 'Close By',
    totalCashSales: 'Total Cash Sales',
    initialChange: 'Initial Change',
    totalCashIn: 'Total cash in',
    totalCashOut: 'Total cash out',
    actualInDrawer: 'Actual in drawer',
    expectedInDrawer: 'Expected in drawer',
    difference: 'Difference',
    voidBillDetail: 'Void bill detail',
    cancelBy: 'Cancel By.',
    cancelDate: 'Cancel Date',
    searchByEmployees: 'Search by employees',
    search: 'Search',
    timeIn: 'Clock in',
    timeOut: 'Clock out',
    time: 'Time',
    hours: 'Hours',
    minute: 'min',
    netTotal: 'Total',
    reason: 'Reason',
    searchByProduct: 'Search by product',
    sortBy: 'Sort By',
    last: 'Lasted',
    price: 'Product price',
    sortByNumMin: 'Min price',
    sortByNumMax: 'Max price',
    documentNo: 'Document No.',
    transactionType: 'Transaction Type',
    updatedBy: 'Updated By',
    transactionType1: 'Sell',
    transactionType2: 'Refund',
    transactionType3: 'Stock in',
    transactionType4: 'Stock out',
    transactionType5: 'Adjust stock in',
    transactionType6: 'Adjust stock out',
    transactionType7: 'Redeem point',
    transactionType8: 'Delete stock in',
    transactionType9: 'Delete stock out',
    transactionType10: 'Edit stock in',
    transactionType11: 'Edit stock out',
    billNo: 'Bill No.',
    docTotal: 'Total',
    customer: 'Customer',
    username: 'Username',
    dateOfBill: 'Date Of Bill',
    selectDate: 'Select Date',
    dialogSelectCategory: 'Please select category for search',
    dialogUpdateDocumentSuccess: 'Update document successfully',
    dialogfound: 'The information was entered incorrectly.',
    isVatIncluded: 'Is VAT Included',
    vatIccludes: 'VAT',
    confirmStock: 'Confirm stock',
    confirm: 'Confirm',
    deleteDoc: 'Delete Document',
    confirmUpdateDocModal: 'Would you like to update stock document?',
    confirmSaveDoc: 'Confirm saving documents',
    confirmDeleteDoc: 'Confirm deleted documents',
    confirmDeleteModal: 'Would you like to deleted stock document?',
    dialogSaveDocumentSuccess: 'Save document successfully',
    createStockIn: 'Create Stock-In',
    confirmSaveDocModal: 'Would you like to save stock document?',
    selectShopPlease: 'Select shop please',
    selectProductPlease: 'Select product please',
    duplicateProduct: 'You have selected this item.',
    productInvilid:
        'The item you selected is invalid. Please contact the system administrator.',
    confirmStockOut: 'Confirm stock out',
    dialogSaveDocumentOutSuccess: 'Save document successfully',
    confirmSaveDocStockOutModal: 'Would you like to save stock out document?',
    adjustDocType: 'AdjustDoc Type',
    adjustDocType1: 'Improve',
    adjustDocType2: 'Reduce',
    beforeAdjust: 'Before Adjust stock',
    afterAdjust: 'After Adjust stock',
    adjust: 'Adjust stock',
    createDoucument: 'Create Document',
    transferType: 'Transfer Type',
    transferType1: 'Transfer in',
    transferType2: 'Transfer out',
    new: 'New',
    finish: 'Finish',
    refDocument: 'Document Reference',
    statusDoc: 'Document Status',
    dialogSaveTransferDocumentSuccess: 'Create transfer doucument successfully',
    destinationShop: 'Destination shop',
    destinationShopBranch: 'Destination branch',
    sourceBranch: 'Source Branch',
    sourceShop: 'Source Shop',
    sourceAddress: 'Source address',
    destinationAddress: 'Destination address',
    saveTransfer: 'Save transfer stock',
    confirmSaveTransfer: 'Would you like to save transfers stock?',
    savinserviceChargegDoc: 'Saving document',
    selectdestinationPlease: 'Please select destination branch ',
    validatedestinationproduct: "In destination branch don't have product",
    validateProduct: 'Please select product',
    created_by: 'By.',
    receive: 'Receive',
    updateDoc: 'Update document',
    cancelDoc: 'Cancel document',
    confirmCancelDoc: 'Do you want to cancel this document?',
    cancelingDoc: 'Canceling documents',
    updatingDoc: 'Updatting documents',
    updateDocFail: "Can't updated documents",
    cancelDocSuccess: 'Cancel documents',
    cancelDocFail: "Can't cancel documents",
    refDocumentTransfer: 'Ref. Document',
    comfrimTransferIn: 'Confirm stansfer stock in',
    dilogcomfrimTransferIn: 'Do you want to transfer stock in document?',
    transferInDetail: 'Transfer stock detail',
    totalNet: 'Net total',
    confirmAdjust: 'Confirm adjust stock',
    createStockOut: 'Create stock out',
    favorite: 'Favorite',
    isOnScreen: 'Is On Screen',
    negotiatePrice: 'Negotiate Price',
    productTypeP: 'General Product',
    productTypeBOM: 'Bill of Meterial',
    productTypeSN: 'Serial Product',
    productTypeSV: 'Service (non-stock)',
    productPLU: 'Other packaging sizes',
    productBOM: 'Meterial',
    productSN: 'Serial Number',
    morePrice: 'Other Prices',
    cost: 'Cost',
    quantityPrice: 'Quantity',
    exceedUc: 'Price',
    changeImg: 'Image change',
    addProduct: 'Add Product',
    selectImg: 'Select image',
    deleteImg: 'Delete image',
    selectImgFromPC: 'Select image from PC',
    confirmSaveImgDialog: 'Do you want to save product?',
    confirmSaveImg: 'Confirm to save product.',
    addPrice: 'Add Price',
    editPrice: 'Edit Price',
    dialigDeleteProduct: 'Do you want to deleted product?',
    confirmDeleteProduct: 'Confirm to delete this product.',
    productType: 'Product Type',
    countProduct: 'Count of product',
    addUnit: 'Add Unit',
    editUnit: 'Edit Unit',
    active: 'Active',
    inactive: 'In Active',
    confirmSaveUnitDialog: 'Do you want to save unit?',
    confirmSaveUnit: 'Confirm to save this unit.',
    dialigDeleteUnit: 'Do you want to deteted unit?',
    confirmDeletUnit: 'Confirm to delete this unit.',
    confirmSaveCategoryDialog: 'Do you want to save category?',
    confirmSaveCategory: 'Confirm to save this category.',
    dialigDeleteCategory: 'Do you want to deteted category?',
    confirmDeletCategory: 'Confirm to delete category.',
    addCategory: 'Add Category',
    editCategory: 'Edit Category',
    bgColor: 'Background color',
    selectbgColor: 'Select background color',
    validateCategoryname: 'Please enter a category name.',
    sequence: 'Sequence',
    enterProduct: 'Please enter product name.',
    enterPrice: 'Please enter product price.',
    selectCategory: 'Please enter a product category.',
    selectUnit: 'Product Unit',
    confirmSaveProduct: 'Do you want to save this product?',
    confirmSaveProductPrice: 'Do you want to save other price?',
    validatepackQty: 'Please enter a Price/Unit.',
    validatepackUc: 'Please enter a price.',
    validatePRU: 'Duplicate data, Please enter again.',
    createProductSuccess: 'Created product is success',
    addSerial: 'Add Serial',
    add: 'Add',
    createSerial: 'Generate Serial number',
    available: 'Ready to sales',
    soldOut: 'Sold Out',
    deleteSuccess: 'Deleted is success. ',
    enterSerial: 'Please enter Serial Number',
    confirmSaveSerial: 'Do you want to save this Serial Number?',
    confirmDelete: 'Do you want to delete this Serial Number?',
    addProductPLU: 'Add packaging sizes',
    img: 'Image',
    SKURatio: 'Ratio',
    editProductPLU: 'Edit packaging sizes',
    enterSKURatio: 'Please enter Ratio',
    comfrimDeletePLU: 'Do you want to delete this packaging sizes?',
    useStepPrice: 'Use step price',
    addBOM: 'Add Material',
    confirmSaveBOM: 'Do you want to save Material?',
    user: 'User',
    userDetail: 'User Detail',
    planDetail: 'Plan Detil',
    currentPlan: 'Current Plan',
    expireDate: 'Expire Date',
    startDate: 'Start Date',
    dialogSignOut: 'Do you want to sign out?',
    confirmSignOut: 'Confirm for sign out',
    countMember: 'All members', //
    addMenber: 'Add member',
    searchMember: 'Search by name email or Tel',
    dateOfMember: 'Date of member',
    recentVisit: 'Recent visit',
    memberName: 'Member name',
    email: 'Email',
    paymentTotal: 'Payment total',
    point: 'Point',
    pointtxt: 'Current Point',
    postalCode: 'Postal code',
    saveing: 'Saving . . .',
    edit: 'Edit',
    editMember: 'Edit member',
    deleteing: 'Deleting . . .',
    dialogDeleteMember: 'Do you want to deleted member ',
    confirmDeleteMember: 'Confirm for delete',
    valtdateMember: 'Please fill information ',
    createMemberSuccess: 'Add member is successfully !!',
    updateMemberSuccess: 'Edit member is successfully !!',
    deleteMemberSuccess: 'Delete member is successfully !!',
    onhandQtyMin: 'Onhand Min',
    onhandQtyMax: 'Onhand Max',
    onhandQtySort: 'OnhandQty',
    onhandQtySortPlaceholder: 'Enter Item Quantity ...',
    perUnit: 'Per Unit',
    validAmountPoint: 'Please Enter Amount of Points for Redemption',
    validCouponValue: 'Please Enter Coupon Value',
    validItemAmount: 'Please Enter Item Amount',
    showProductAll: 'All Product type',
    updateAt: 'Last update',
    sortName: 'Name',
    stockUnit: 'Cost/Unit',
    deleteCategory: 'Deleted category',
    selectd: 'Select',
    searchProduct: 'Search Product',
    reset: 'Reset',
    selectdProduct: 'Select product',
    information: 'Information',
    adjustStockList: 'Adjust stock product',
    adjustDetail: 'Add product to adjust stock list add setting product',
    transferoutList: 'Transfer stock product',
    addProductTransfer:
        'Add product to transfer stock list add setting product',
    transferDetail: 'Product to Stock out list add setting product',
    transferinList: 'Product to Stock in list add setting product',
    stockOutList: 'Stock out product',
    addstockOutList: 'Add product to Stock out list add setting product',
    stockinList: 'Stock in product',
    addProductStockin: 'Add product to Stock in list add setting product',
    checkCount: 'Check stock',
    createAdjustStock: 'Create adjust stock',
    memberDetail: 'Member infomation',
    package: 'Package detail',
    startPackate: 'Start Package',
    endPackate: 'End Package',
    name: 'Name',
    productImg: 'Product image',
    fistname: 'Fristname',
    lastname: 'Lastname',
    saveInformation: 'Save infomation',
    confirmSaveMember: 'Do yo want save member ',
    yesOrNo: 'yes or no',
    totaValue: 'Total',
    deleteMember: 'Delete member',
    frequently: 'Frequently product',
    recentPurchase: 'Last purchase',
    paymentDescription: 'Payment description',
    validateInfomation: 'Please input information',
    validateTel: 'Tel is incorrect',
    validateEmail: 'Email is incorrect',
    saveSuccess: 'Save is success',
    txtcustomer: 'Customer',
    customerName: 'Customer name',
    customerCount: 'Count of customer',
    addCustomer: 'Add customer',
    permissionManage: 'Permission',
    mainMenu: 'Main Menu',
    page: 'Page',
    show: 'Show',
    delete: 'Delete',
    administratorTools: 'Administrator tools',
    deviceName: 'Device Name', //ชื่ออุปกรณ์
    lastSyncProduct: 'Last Sync', //เวลาใช้งานล่าสุด
    version: 'Version',
    posstatus: 'Status', //สถานะการเชื่อมต่อกับระบบ
    posNumber: 'POS Number',
    titleSilomconnect: 'Never miss any sales with services from',
    updateSilom: 'Update daily sales via LINE for free',
    addfriend: 'Add friend',
    enterToReciveService: 'Press to receive a code for using the service.',
    verifyCodeToUsed:
        'To start using the system. You can press to receive a code to verify your identity.',
    verifyfivemin: 'The verification code can be used within 5 minutes.',
    yourVertify: 'Your verify code is', //รหัสของคุณคือ
    pressCode: 'Press receive code', //กดรับรหัส
    exCode: 'The code expires within', //รหัสหมดอายุภายใน
    sessionEx: 'Login session expired. Please login again.', // เซสชันการเข้าสู่ระบบหมดอายุ กรุณาล็อกอินใหม่อีกครั้ง
    refresh: 'Refresh',
    selectedFile: 'Selected File',
    fileSize: 'File size shouldn\'t bigger than 2 MB.',
    isBeingToUsed: 'Is being used',
    notInUse: 'Not in use',
    delete: 'Delete',
    confirmUpdateInformation: 'Confirm to update information',
    memberCount: 'Count',
    orderMachine: 'Order Device',
    textMember: 'Member',
    manageShop: 'Shop information management',
    lansetting: 'Shop setting language and timezone',
    timezoneSetiing: 'Setting time zone for shop',
    deliveryProviderSetting: 'Delivery Providers for shop ',
    toolsSetting: 'Manage POS machines for shop',
    checkStockPLU: 'Product balace < 0',
    searching: 'Searching',
    allProduct: 'All Product',
    foundProductOnhand: 'Found product have stock onhand < 0 ',
    confirmAdjustBefor: 'Do you want to adjust by system automatic?',
    requireAdjust: 'Yes',
    imrequireAdjust: 'No',
    islimitItemTodoc: 'You can select up to 200 items / 1 document.',
    barcodePrint: 'Barcode Print',
    printEx: 'Print & Example',
    print: 'Print',
    downloadAndExport: 'Download and print',
    selectFormat: 'Select paper format',
    peperFormat: 'Paper format',
    optional: 'Optional',
    standardA427: 'Standard A4 : 27 items / page',
    standardA450: 'Standard A4 : 52 items / page',
    standardA460: 'Standard A4 : 60 items / page',
    standardA556: 'Standard A4 : 56 items / page (sticker size:A5)',
    standardA550: 'Paper A5 : 52 items / page',
    standardA560: 'Paper A5 : 60 items / page',
    countBarcode: 'Count barcode',
    barcode: 'Barcode',
    setFontsize: 'Font setting',
    accessPermissionDes: 'Assign access for users.',
    deleteUser: 'Confirm to delete user',
    confirmDeleteUser: 'Would you like to deleted user ?',
    passAtless: 'Password should be at least 6 characters.',
    inValidPass: 'Incorrect password',
    createBy: 'Created By',
    adjustAutoDes:
        "If you don't want the system to update automatically May cause the total balance of the product to be wrong.",
    addUser: 'Add user',
    isMenu: 'Is on menu for customer',
    menuSmart: 'Set up product menu',
    smartSettingTitle: 'Menu Setting',
    smartSetting: 'Setting menu for Silom CRM ',
    confirmEnable: 'Do you want to enable menu ?',
    confirmDisEnable: 'Do you want to disable menu ?',
    confirmSelfOrderEnable: 'Do you want to enable self-order menu ?',
    confirmSelfOrderDisable: 'Do you want to disable self-order menu ?',
    enable: 'Enable',
    disable: 'Disable',
    usabilityStatus: 'Usage Status',
    manageSmartOnProduct:
        'You caadd/edit" the list of products you want to show on menu for customer at the page.',
    manageSmartOnCategory:
        'You can "add/edit" the list of category you want to show on menu for customer at the page.',
    turnonSmart: 'and enable menu',
    manageShopDes: 'You can refer to the user manual in -',
    desSmartMenu:
        'Set up product menu for displaying the E-menu  on Line for easier customer access and can order products by themself.',
    link: 'link',
    createTranferOut: 'Create tranfer out document',
    roundingValue: 'Rounding Adj.',
    unRoundingValue: 'Unrounding Adj.',
    limitItems: 'Can select product to limit 200 items',
    IncomeCategory: 'Income ',
    ExpenseCategory: 'Expense ',
    saveBy: 'Save by',
    saveDate: 'Save date',
    drawerCate: 'Categories',
    openingHours: 'Opening hours',
    shopLocation: 'Shop location',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    lat: 'Latitude',
    lng: 'Longitude',
    shopCoverSetting: 'Shop cover setting',
    shopCoverSettingDes: 'Setting cover shop for displays on screen customer',
    locationSettingDes: 'Location setting ',
    updatelocation: 'Update location', //Update location
    searchLocation: 'Search Location ', //Search Location
    imaginaryLine: 'Imaginary Line', //Imaginary Line
    openingHoursDes: 'Setting opening time for shop',
    paymentDescriptionConfig: 'Select Payment Method',
    creditCard: 'Credit Card',
    promptPay: 'Promptpay',
    coupon: 'Coupon',
    quickpay: ' Krungsri Quick Pay',
    alipay: 'Alipay',
    linepay: 'Rabbit LINE Pay',
    thaidotcom: 'Thai Dot Com Payment',
    mounth: 'mounth',
    dayMY: 'Date',
    item: 'Items',
    byStockIn: 'Stock-in',
    stockOutOther: 'Other',
    drawerSales: 'Sales',
    sumIncomeExpenses: 'Summary of income-expenses for ', //Summary of income-expenses for the month
    incomeOther: 'Income Other',
    loss: 'Loss',
    grossProfit: 'Gross Profit',
    newUser: 'New user',
    userType: 'User Type',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    ParticipatingProducts: 'Select products',
    descriptionSilomOrder: 'Select products for order.silompos.com',
    cashierManagement: 'Cashier Management',
    cashierManageButton: 'Manage',
    cashierUsernamePassword: 'Create Username/Password',
    cashierCRUDDescription: 'For login order.silompos.com',
    password: 'Password',
    placeholderPassword: '6 characters minimum',
    resetPassword: 'Reset password',
    oldPassword: 'Old password',
    oldPasswordPlaceholder: 'Old password',
    newPassword: 'New password',
    purchaseDetail: 'Purchase Details',
    uploadImage: 'Upload Image',
    keyword: 'keyword',
    firstname: 'First Name',
    lastname: 'Last Name',
    province: 'Province',
    district: 'District',
    subdistrict: 'Sub District',
    addressNumber: 'Address',
    setAsDefaultAddress: 'Set as Default address',
    setAsTaxAddress: 'Set as Tax address',
    setAsShippingAddress: 'Set as Shipping address',
    contact: 'Contact',
    setAsDefault: 'Set as default',
    addressLists: 'Address lists',
    default: 'Default',
    taxAddress: 'Tax invoice',
    shippingAddress: 'Shipping',
    addAddress: 'Add',
    createdAt: 'Created',
    updatedAt: 'Updated',
    by: 'by',
    readyForSale: 'ready for sale',
    sold: 'sold',
    delete: 'delete',
    items: 'Items',
    itemDiscount: 'Item discount',
    failedToSave: 'Failed to save!',
    dateOfBirth: 'Date of birth',
    menu: 'Menu',
    dashboardMenu: 'Dashboard',
    transactionMenu: 'Transaction',
    currentBillMenu: 'Current Bill',
    reportMenu: 'Report',
    orderDeviceMenu: 'Order Device',
    memberMenu: 'Member',
    inventoryMenu: 'Inventory',
    productMenu: 'Product',
    selfOrderMenu: 'Sales Channels',
    settingMenu: 'Setting',
    salesReportByDate: 'Sales by Date',
    salesReportByBill: 'Sales by Bill Detail',
    salesQuantitySummaryByProductReport: 'Sales by Product',
    quantitySummaryByPLUReport: 'Sales by Product (PLU)',
    nonSellingProduct: 'Non Selling Products',
    salesSummaryByCashierReport: 'Sales by Cashiers',
    salesSummaryByCategoryReport: 'Sales by Category',
    paymentReport: 'Payment',
    salesTaxReport: 'Sales Tax',
    drawerReport: 'Drawer',
    voidBillReport: 'Void bill',
    timesheetReport: 'Timesheet',
    stockInByListReport: 'Stock in by Product',
    stockOutByListReport: 'Stock out by Product',
    nonAdjustStockProductReport: 'Non adjust stock product',
    exportProductReport: 'Export product',
    salesSummaryByOrderReport: 'By Order Device',
    salesSummaryProductByOrderReport: 'By Product',
    voidBillByOrderReport: 'Void bill',
    inventoryReport: 'Inventory Report',
    inventoryReportByPLU: 'Inventory Report by PLU',
    inventoryReportBySerial: 'Inventory Report by Serial No.',
    stockMovement: 'Stock Movement',
    stockInDocument: 'Stock-In document',
    stockOutDocument: 'Stock-Out document',
    adjustStockDocument: 'Adjust Stock document',
    selfOrder: 'Self Order',
    cashier: 'Cashier',
    permission: 'Permission',
    menuSetting: 'Menu Setting',
    PLU: 'PLU',
    management: {
        txtManagement: 'Management',
        user: 'Account',
        shopBranch: 'Shop and Branch',
        productManagement: 'Product',
        unitManagement: 'Unit',
        categorytManagement: 'Category',
        inventory: 'Inventory',
        stockIn: 'Stock-In',
        stockOut: 'Stock-Out',
        adjustStock: 'Adjust Stock',
        checkStock: 'Check stock',
        stockMovement: 'Stock Movement',
        transferStock: 'Transfer Stock',
        reportByPLU: 'Inventory Report by PLU',
        reportBySKU: 'Inventory Report',
        txtreportBySKU: 'Inventory Report',
        txtreportByPLU: 'Inventory Report by PLU',
        stockInDoc: 'Stock-In document',
        stockOutDoc: 'Stock-Out document',
        adjustStockDoc: 'Adjust Stock document',
        transferStockDoc: 'Transfer stock document',
        transferStockInDoc: 'Transfer stock in document',
        transferStockOutDoc: 'Transfer stock out document',
    },
    report: {
        dashboard: 'Dashboard',
        transaction: 'Transactions',
        currentbill: 'Current Bill',
        daily: 'By Date',
        txtdaily: 'Sales report by Date',
        dailyDes: 'Sales report by date detail',
        sellbyProduct: 'By Bill Detail',
        txtsellbyProduct: 'Sales report by Bill Detail',
        sku: 'By Product',
        txtsku: 'Sales report by Product',
        plu: 'By Product (PLU)',
        textPlu: 'Sales report by Product (PLU)',
        paymentreport: 'Payment Report',
        txtpaymentreport: 'Payment Report',
        timesheet: 'Timesheet',
        txtTimesheet: 'Timesheet report',
        unsell: 'Non Selling Products',
        txtUnsell: 'Non Selling Products',
        byCategory: 'By Category',
        txtbyCategory: 'Sales report by Category',
        byCashier: 'By Cashier',
        txtbyCashier: 'Sales report by Cashiers',
        salesTax: 'Sales Tax report',
        txtSalesTax: 'Sales Tax report',
        voidbill: 'Void Bill',
        txtvoidbill: 'Void bill report',
        closedSale: 'Closed Sale',
        txtCloseSale: 'Drawer report ',
        salesByOrder: 'Sales summary',
        productByOrder: 'By Product ',
        unsalesByOrder: 'Void Bill ',
        txtDeliveryRoport: 'Payment by delivery report',
        txtCustomReport: 'Payment by custom report',
        txtSalesByOrder: 'Sales product by Order Device',
        txtDailyOrder: 'Sales by Order Device report',
        txtViodOrder: 'Void bill by Order Device',
        nonadjust: 'Non adjust stock product',
        txtnonadjust: 'Non adjust stock product report',
        stockInReport: 'Stock in by Product report',
        stockOutReport: 'Stock out by Product report',
        stockIn: 'Stock in by Product',
        stockOut: 'Stock out by Product',
        exportProduct: 'Export Product',
        txtExportProduct: 'Export product report',
        cashDrawer: 'Drawer',
        txtCashDrawerr: 'Cash management report',
        cashRevenue: 'Cash Revenue',
    },
    drawer: {
        cashDrawer: 'Drawer',
        txtCashDrawerr: 'Cash management report',
        cashRevenue: 'Summary of income-expenses',
        txtcashRevenue: 'Summary of income-expenses report',
    },
    member: {
        txtmember: 'Member',
        register: 'Shop register (LINE OA)',
        loyaltyPoint: 'Loyalty Point',
        storeCredit: 'Store Credit',
        deal: 'Deal',
        giftVoucher: 'Voucher',
        referral: 'Invite Friends',
        crm: 'Silom CRM',
    },
    unableStockDescription:
        'Unable to edit product due to stock update after document creation.',
    noItem: 'No item',
    enterProductName: 'Enter product name',
    ByCategory: 'By Category',
    ByCategoryDetail: 'By Category detail',
    disabledCouponAnounce: 'Announcement: The coupon system for free product redemption is temporarily unavailable. <br> We are working to improve the system for better efficiency. However, discount coupons can still be used as usual. Thank you for your understanding and support!',

}

export default messages
