<template>
  <router-view></router-view>
</template>

<script>
export default {}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-regular-stright/css/uicons-regular-stright.css');
</style>
