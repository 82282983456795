const messages = {
    seeManual: 'หรือสามารถศึกษาการใช้งานระบบได้จากเมนู',
    incaseHasLineOA: 'กรณีคุณมีบัญชี Line OA ของร้านค้าแล้ว สามารถลงทะเบียนใช้งานระบบได้',
    enablePointDialog: 'คุณเปิดใช้งานการตั้งค่าคะแนนสะสม กรุณากรอกข้อมูล และบันทึกอีกครั้งเพื่อยืนยันการใช้งาน',
    settingPointDialogWarning: 'หากคุณเปลี่ยนแปลงการตั้งค่าสะสมคะแนน การทำรายการสะสมคะแนนจะมีผลต่อทุกยอดบิลทั้งหมด นับตั้งแต่การเปลี่ยนแปลงการตั้งค่าทันที',
    confirmToSuspenPointAccomulate: 'คุณต้องการปิดใช้งานการสะสมคะแนนใช่หรือไม่?',
    suspenPointDialog: 'หากคุณปิดใช้งานการตั้งค่าสะสมคะแนน การทำรายการสะสมคะแนนจะมีผลต่อทุกยอดบิลทั้งหมดนับตั้งแต่การเปลี่ยนแปลงการตั้งค่าทันที',
    mamberNotFound: 'ไม่พบสมาชิก',
    vilidCouponName: 'กรุณากรอกชื่อคูปอง',
    couponStatus: 'สถานะคูปอง',
    couponCode: 'รหัสคูปอง',
    unlimited: 'ไม่จำกัด',
    deleteCouponDialog: ' กรณีที่คูปองถูกลบจะไม่สามารถกู้คืนได้ จะต้องสร้างคูปองใหม่เท่านั้น',
    deleteCouponConfirmation: 'ต้องการลบคูปองนี้?',
    couponUsageDetail: 'รายละเอียดการใช้คูปอง',
    deleteCoupon: 'ลบคูปอง',
    notEnoughPoint: 'คะแนนของสมาชิกไม่เพียงพอ',
    validSelectMember: 'กรุณาเลือกสมาชิก',
    validPointInput: 'จำนวนคะแนนไม่ถูกต้อง',
    nolimitTimeOrUntilNoStock: 'ไม่จำกัดระยะเวลา หรือจนกว่าสินค้าจะหมด',
    points: 'คะแนน',
    enterDetail: 'กรอกรายละเอียด',
    validEnterDetail: 'กรุณากรอกรายละเอียด',
    confirmToSaveParicipateItem: 'คุณต้องการบันทึกสินค้าที่ร่วมรายการ',
    confirmToSaveParicipateItemTitle: 'บันทึกสินค้าที่ร่วมรายการ',
    confirmItem: 'ยืนยันสินค้า',
    value: 'มูลค่า',
    createCouponSuccess: 'สร้างคูปองสำเร็จ',
    couponAmountValidate: 'กรุณากรอกจำนวนคูปองอย่างน้อย 1 คูปอง',
    limit: 'จำกัดสิทธิ์',
    noLimitTime: 'ไม่จำกัดระยะเวลา',
    couponPeriod: 'ระยะเวลารับคูปอง',
    usePoint: 'ใช้คะแนน',
    showCouponToStaff: '1. แสดงคูปองต่อพนักงาน',
    specialRightsNote: '2. สิทธิพิเศษนี้ไม่สามารถเปลี่ยน แลก ทอน เป็นเงินสดได้',
    reservationRights: '3. สงวนสิทธิ์ในการเปลี่ยนแปลง แก้ไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า',
    couponUsageCondition: 'เงื่อนไขการใช้คูปอง',
    showCouponCondition: 'แสดงค่าเริ่มต้นเงื่อนไขการใช้คูปอง',
    couponAmount: 'จำนวนคูปองทั้งหมด',
    couponPerMember: 'คูปองต่อสมาชิก',
    limitPerPerson: 'จำกัดจำนวนต่อ 1 คน',
    redeemPerMenber: 'การรับต่อสมาชิก',
    couponAmountCondition: 'เงื่อนไขจำนวนคูปอง',
    couponExpiration: 'อายุคูปอง',
    couponExpiry: 'อายุคูปอง หลังจากกดรับ',
    specifyCouponPeriod: 'กำหนดระยะเวลาการใช้งานคูปอง',
    couponValuePlacehoolder: 'กรอกมูลค่าคูปอง ...',
    couponValue: 'มูลค่าคูปอง',
    remainingCoupon: 'คูปองคงเหลือ',
    amountPointPlaceholder: 'กรอกคะแนนสำหรับแลกของรางวัล ...',
    amountPoint: 'จำนวนคะแนนที่ใช้แลก',
    validAmountPoint: 'กรุณาจำนวนคะแนนที่ใช้แลก',
    validCouponValue: 'กรุณากรอกมูลค่าคูปอง',
    validItemAmount: 'กรุณากรอกจำนวนสินค้า',
    couponName: 'ชื่อคูปอง',
    inputCouponName: 'กรอกชื่อคูปอง',
    validInputCouponName: 'กรุณากรอกชื่อคูปอง',
    redeemItem: 'แลกสินค้า',
    discountCoupon: 'คูปองส่วนลด',
    selectCouponType: 'เลือกประเภทคูปอง',
    registerLineOA: "สมัครบัญชี Line OA",
    registerStore: "ลงทะเบียนร้านค้า",
    setLoyaltyPoints: "ตั้งค่าคะแนนสะสม",
    createRewardCoupons: "สร้างคูปองของรางวัล",
    createEVoucher: "สร้างบัตรกำนัล (E-Voucher)",
    createPreVoucher: "สร้างบัตรกำนัล (Pre-Voucher)",
    importRetrieveLoyaltyPoints: "นำเข้า/เรียกคืนคะแนนสะสม",
    setRewardPointCards: "ตั้งค่าบัตรสะสมแต้ม",
    setAutoNotifications: "ตั้งค่าแจ้งเตือนอัตโนมัติ",
    productMenu: "เมนูสินค้า",
    home: 'หน้าหลัก',
    fullName: 'ชื่อ - สกุล',
    posterDialog: 'เพื่อสมัครสมาชิก สะสมแต้ม และแลกของรางวัล',
    scan: 'แสกน',
    justAddFriend: 'แค่เพิ่มเราเป็นเพื่อน',
    posterExample: 'ตัวอย่างโปสเตอร์',
    posterExampleDialog: 'สมัครสมาชิก สะสมแต้ม แลกของรางวัลง่ายๆ',
    downloadPoster: 'ดาวน์โหลดโปสเตอร์',
    contactInformation: 'ข้อมูลการติดต่อ',
    addEditContactAtSetting: 'เพิ่ม/แก้ไขข้อมูลการติดต่อของร้านค้าได้จากเมนูตั้งค่า',
    addContactInformation: 'เพิ่มข้อมูลการติดต่อ',
    editTextDialog: 'แก้ไขข้อความที่ใช้แสดงบนโปสเตอร์',
    editText: 'แก้ไขข้อความ',
    uploadQR: 'อัพโหลดคิวอาร์โค้ด',
    QRAbleToDownload: 'รูปภาพคิวอาร์โค้ด Line OA ของร้านค้าสามารถดาวน์โหลดได้',
    uploadQRCodeFile: 'อัพโหลดไฟล์คิวอาร์โค้ด',
    sent: 'ส่ง',
    validInputContact: 'กรุณากรอกข้อมูลอย่างน้อย 1 อย่าง ก่อนทำการบันทึก',
    website: 'เว็บไซต์',
    inputPhoneNumber: 'กรุณาใส่เบอร์โทรศัพท์ติดต่อ',
    contactDialog: 'จัดการข้อมูลการติดต่อของร้านค้า',
    activeNotifyDialog: 'คุณเปิดใช้งานการแจ้งเตือนอัตโนมัติ กรุณาบันทึกข้อมูลเพื่อยืนยันการใช้งาน',
    confirmToDisableNotify: 'คุณต้องการปิดการแจ้งเตือนอัตโนมัติใช่หรือไม่?',
    inputAccessTokenPlaceholder: 'กรุณาใส่ Access Token ที่ได้จาก Line Notify',
    manualGenerateToken: 'ดูคู่มือการออก Access Token ได้ที่นี่',
    lineNotifyDialog: 'รู้ทุกความเคลื่อนไหวการการใช้บริการ และการเข้าออกของคะแนนด้วยบริการแจ้งเตือนอัตโนมัติผ่าน Line Notify',
    amountMoney: 'จำนวนเงิน',
    inputMoneyAmount: 'กรอกจำนวนเงิน',
    settingRateLoyaltyPotint: 'ตั้งค่าอัตราส่วนการแปลงคะแนนสะสม จากจำนวนเงินต่อ 1 คะแนน',
    shopDoNotSetFormmatDialog: 'ระบบจะคำนวณในรูปแบบของการปัดเศษ เช่น 10 บาทต่อ 1 คะแนน ลูกค้าซื้อสินค้า 15 บาทจะได้รับคะแนนสะสม 1 คะแนน',
    shopDoNotSetFormmat: 'กรณีร้านค้าไม่ได้กำหนดรูปแบบ',
    calculationFormat: 'รูปแบบการคำนวน',
    somethingWentWrong: 'เกิดข้อผิดพลาดบางอย่าง กรุณาตรวจสอบข้อมูล',
    saveLoyaltyPointSettingSuccess: 'บันทึกการตั้งค่าคะแนนสะสมเรียบร้อยแล้ว',
    setConversionRate: 'กำหนดอัตราส่วนการแปลงคะแนนสะสม',
    activeMembershipWithSilom: 'คุณเปิดใช้งานระบบสมาชิกกับ Silom POS กรุณากรอกข้อมูล และบันทึกอีกครั้งเพื่อยืนยันการใช้งาน',
    confirmToDisableCrm: 'คุณต้องการปิดใช้งานระบบสมาชิกใช่หรือไม่?',
    confirmToDisableCrmDialog: 'หากคุณปิดใช้งานระบบสมาชิกการทำรายการเกี่ยวกับระบบสมาชิกจะมีผลต่อทุกยอดบิลทั้งหมดนับตั้งแต่การเปลี่ยนแปลงการตั้งค่าทันที',
    confirmSaveData: 'คุณต้องการยืนยันการบันทึกข้อมูลใช่หรือไม่?',
    clickHere: 'คลิกที่นี่',
    shopCanSetUpLoyatyPointAtMenuSetting: 'ร้านค้าสามารถตั้งค่าการสะสมแต้มได้ที่แถบเมนูตั้งค่า -> ตั้งค่าคะแนนสะสม',
    checkingData: 'กำลังตรวจสอบข้อมูล',
    setupInformation: 'แก้ไขข้อมูล',
    orSeeDetailsAtMenu: 'หรือดูรายละเอียดได้ที่เมนู',
    inCaseNoLineOA: 'กรณีร้านค้ายังไม่มีบัญชีทางการของ Line Official สามารถสมัครได้ที่',
    businessPoster: 'โปสเตอร์ธุรกิจ',
    businessPosterDialog: 'คุณสามารถพิมพ์โปสเตอร์ วางไว้ที่หน้าร้านให้ลูกค้าเพิ่มเพื่อนบนไลน์ เพื่อให้ลูกค้าของคุณเข้าถึงร้านค้าได้อย่างง่ายดาย',
    setUpAutoNotify: 'ตั้งค่าแจ้งเตือนอัตโนมัติ',
    setUpAutoNotifyDialog: 'ตั้งค่าการแจ้งเตือนอัตโนมัติผ่าน Line Notify',
    settingRoyaltyPoint: 'ตั้งค่าคะแนนสะสม',
    shopRegister: 'ลงทะเบียนร้านค้า',
    contactCustomerService: ' ติดต่อฝ่ายบริการลูกค้า',
    noPaymentDetail: 'ไม่มีข้อมูลการชำระเงิน',
    expired: 'หมดอายุแล้ว',
    voucherCode: 'รหัสบัตรกำนัล',
    additionalDetail: 'รายละเอียดเพิ่มเติม',
    index: 'ลำดับที่',
    paidBy: 'ชำระโดย',
    searchBy: 'ค้นหาโดย',
    editAt: 'แก้ไขเมื่อ',
    duration: 'ระยะเวลาการใช้งาน',
    unspecified: 'ไม่ระบุ',
    voucherAmount: 'จำนวนวอเชอร์',
    printCount: 'ครั้งที่พิมพ์',
    editHistory: 'ประวัติการแก้ไข',
    extendAllVoucher: 'บัตรกำนัลทั้งหมดในรายการจะถูกขยายระยะเวลาการใช้งาน',
    noLimitTimeofUse: 'ไม่จำกัดระยะเวลาการใช้งาน',
    cancelThisVoucher: 'ยกเลิกบัตรกำนัลชุดนี้',
    cancelThisVoucherDialog: 'ต้องการยกเลิกบัตรกำนัลชุดนี้?',
    cancelThisVoucherDialog2: 'กรณีที่บัตรกำนัลถูกยกเลิกจะไม่สามารถกู้คืนได้และบัตรกำนัลชุดนี้ทั้งหมดจะไม่สามารถใช้งานได้',
    extendDuration: 'ขยายระยะเวลาการใช้งาน',
    printVoucher: 'พิมพ์บัตรกำนัลหน้านี้',
    voucherIsCanceled: 'บัตรกำนัลถูกยกเลิกการใช้งาน',
    voucherIsDeleted: 'บัตรกำนัลถูกลบออกจากระบบ',
    createVoucherCompleted: 'สร้างบัตรกำนัลสำเร็จ',
    example: 'ตัวอย่าง',
    startingNumber: 'ตัวเลขเริ่มต้น',
    startingNumberPlaceholder: 'กรอกตัวเลขเริ่มต้น เช่น 1 ...',
    code: 'ตัวอักษร',
    codePlaceholder: 'ระบุตัวอักษรเริ่มต้นที่ต้องการใช้ เช่น VOU ...',
    incaseNoCodeDialog: 'กรณีไม่ต้องการใส่ตัวอักษรให้เว้นว่างไว้',
    custom: 'กำหนดเอง',
    createBySystemDefault: 'สร้างโดยระบบ',
    creationOption: 'ตัวเลือกการสร้าง',
    incaseNoUsagePeriod: 'กรณีไม่กำหนดระยะเวลาการใช้งานให้เว้นว่างไว้',
    limit1000Voucher: 'จำกัดจำนวนสูงสุด 1,000 บัตรกำนัล(Voucher)',
    voucherAmount: 'จำนวนบัตรกำนัล',
    inputVoucherValue: 'กรอกมูลค่าต่อบัตรกำนัล',
    inputVoucherDisplayName: 'ระบุชื่อบัตรกำนัลที่ต้องการใช้แสดง',
    generalDetail: 'รายละเอียดทั่วไป',
    create: 'สร้าง',
    noExpiryDate: 'ไม่กำหนดระยะเวลาหมดอายุ',
    expiryDate: 'วันหมดอายุ',
    voucherNumber: 'เลขที่บัตรกำนัล (Voucher No.)',
    receivedDate: 'วันที่รับ',
    couponReceivedDate: 'วันที่รับคูปอง',
    couponUsedDate: 'วันที่ใช้คูปอง',
    wantToCancelVoucher: 'ต้องการยกเลิกบัตรกำนัลนี้?',
    voucherCanNotBeRecovered: 'กรณีที่บัตรกำนัลถูกยกเลิกจะไม่สามารถกู้คืนได้',
    voucherCanNotBeRecovered2: 'จะต้องสร้างบัตรกำนัลใหม่เท่านั้น',
    cancelAllVoucher: 'ยกเลิกบัตรกำนัลทั้งหมด',
    cancelVoucher: 'ยกเลิกบัตรกำนัล',
    createNewCard: 'สร้างบัตรสะสมแต้มใหม่',
    suspenCardSucces: 'บัตรสะสมแต้มถูกระงับการใช้งานเรียบร้อยแล้ว',
    pleaseApcceptTerm: 'กรุณากดยอมรับเงื่อนไขการใช้บริการ',
    pleaseSelectSuspenPeriod: 'กรุณาเลือกวันระงับการใช้บัตร และวันสิ้นสุดการแจกแต้มให้ถูกต้อง',
    settingCompleted: 'ตั้งค่าสำเร็จ',
    selectProductUnit: 'กรุณาเลือกหน่วยสินค้า',
    inputProductAmount: 'กรุณาระบุจำนวนสินค้า',
    errorAlert: 'มีบางอย่างผิดพลาด กรุณาตรวสอบข้อมูลอีกครั้ง!',
    completely: 'สำเร็จ',
    sendVoucherComplete: 'ส่งบัตรกำนัลสำเร็จให้แก่คุณ',
    validateInput: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    validDateInput: 'กรุณาเลือกระยะเวลาให้ถูกต้อง',
    here: 'ที่นี่',
    haveNoVoucher: 'คุณไม่มีรายการบัตรกำนัล สามารถสร้างบัตรกำนัลได้',
    confirmToSend: 'ยืนยันส่ง',
    confirmToSendTo: 'ให้แก่คุณ',
    condition: 'หมายเหตุ / เงื่อนไขการใช้บริการเพิ่มเติม',
    attachFile: 'แนบไฟล์',
    attachFileDialog: 'แนบหลักฐานการชำระเงิน ...',
    additionalNote: 'บันทึกเพิ่มเติม',
    additionalNoteDialog: 'บันทึกข้อมูลเพิ่มเติม เช่น เลขที่บัญชี หรือวันเวลาการชำระ',
    inputPaymentAmount: 'กรอกจำนวนเงินที่รับชำระ',
    paymentAmount: 'ยอดชำระ',
    paymentMethod: 'ช่องทางการชำระ',
    paymentMethodDialog: 'เลือกช่องทางการชำระเงิน',
    customPaymentMethod: 'โปรดระบุช่องทางการชำระเงิน',
    selectMemberBySearch: 'เลือกสมาชิก สามารถค้นหาได้จากชื่อ เบอร์โทรศัพท์ หรืออีเมล์',
    startFrom: 'ใช้งานได้ตั้งแต่วันที่',
    selectVoucher: 'เลือกบัตรกำนัล',
    createEVoucher: 'สร้างบัตรกำนัลในรูปแบบอิเล็กทรอนิกส์ ( E-Voucher )',
    sendEVoucher: 'ส่งบัตรกำนัลในรูปแบบอิเล็กทรอนิกส์ ( E-Voucher )',
    createEVoucher2: 'ซึ่งลูกค้าสามารถใช้งานได้ผ่าน Line OA ของร้านค้า',
    preview: 'ดูตัวอย่าง',
    setPeriod: 'กำหนดระยะเวลา',
    setPeriodAfterRecivcedVoucher: 'กำหนดวันหมดอายุหลังจากได้รับบัตรกำนัล',
    periodAfterRecivedVoucher: 'หมดอายุหลังจากได้รับบัตรกำนัล',
    voucherExpire: 'กำหนดระยะเวลาการใช้งานบัตรกำนัล',
    voucherValue: 'มูลค่าบัตรกำนัล',
    voucherDetailPlaceholder: 'กรอกรายละเอียดที่อธิบายถึงบัตรกำนัล เช่น เงื่อนไขการใช้บัตรกำนัล ...',
    voucherNamePlaceholder: 'กรอกชื่อบัตรกำนัลที่จะแสดง ...',
    voucherName: 'ชื่อบัตรกำนัล',
    createVoucher: 'สร้างบัตรกำนัล',
    eVoucherUsage: 'การใช้งานบัตรกำนัลอิเล็กทรอนิกส์',
    preVoucherUsage: 'การใช้งานบัตรกำนัลล่วงหน้า',
    eVoucher: 'บัตรกำนัลอิเล็กทรอนิกส์ ( E-Voucher )',
    allVoucher: 'บัตรกำนัลทั้งหมด',
    sendVoucherHistory: 'ประวัติการส่งบัตรกำนัล',
    sendVoucherHistoryDialog: 'ประวัติการส่งบัตรกำนัลอิเล็กทรอนิกส์ (E-Voucher) สามารถค้นหาโดยโค้ด หรือชื่อสมาชิก พร้อมทั้งแสดงสถานะการใช้งาน',
    sendVoucher: 'ส่งบัตรกำนัล',
    preVoucher: 'บัตรกำนัลล่วงหน้า (Pre-Voucher)',
    preVoucherDialog: 'ออกบัตรกำนัลล่วงหน้าในลักษณะของการพิมพ์บัตรกำนัลเป็นชุดแล้วแจกจ่ายให้แก่สมาชิกภายหลัง',
    createPreVoucher: 'สร้างพรีวอเชอร์',
    haveNotActivateCard: 'คุณยังไม่ได้เปิดใช้งานบัตรสะสมแต้ม',
    haveNotActivateCardDialog: 'สามารถตั้งค่าและเปิดใช้งานบัตรได้ที่แถบเมนูตั้งค่าบัตรสะสมแต้มหรือศึกษาการใช้งานระบบเพิ่มเติมได้จาก',
    searchMemberByName: 'ค้นหาโดยชื่อ...',
    loyaltyPointMember: 'สมาชิกสะสมแต้ม',
    loyaltyPointMemberDialog: 'แสดงรายชื่อสมาชิกที่สะสมแต้ม และค้นหาตามชื่อได้',
    currentPoint: 'แต้มปัจจุบัน',
    redeemTime: 'แลกของรางวัล (ครั้ง)',
    info01: 'ร้านค้าจะต้องทำการกรอกข้อมูลการสร้างใหม่ทั้งหมด',
    inCaseCreateNewCard: 'กรณีสร้างบัตรสะสมแต้มใหม่ ระบบจะลบบัตรสะสมแต้มใบเดิม',
    viewDetail: 'ดูรายละเอียด',
    participateItems: 'สินค้าที่ร่วมรายการ',
    createNewCard: 'สร้างบัตรใหม่',
    suspendCardSpacified: 'บัตรสะสมแต้มจะถูกระงับการใช้ตามกำหนดการนี้',
    usageStatus: 'สถานะการใช้งาน',
    suspenCard: 'ระงับบัตร',
    consent: 'ข้าพเจ้ายอมรับรายละเอียด และเงื่อนไขในการใช้บริการ',
    noteCardSuspension: 'คุณจะไม่สามารถแก้ไข หรือยกเลิกวันระงับการใช้บัตรได้การตั้งค่าและข้อมูลที่เกี่ยวข้องทั้งหมดจะถูกรีเซ็ตรวมถึงแต้มที่แจกให้ลูกค้าแล้วคุณจะไม่สามารถเปิดใช้บัตรที่ถูกระงับได้เป็นเวลา 1 วันหลังจากระงับบัตร',
    pleaseUseCard: ' ,กรุณาใช้บัตรก่อนวันดังกล่าว ทั้งนี้คุณสามารถสะสมแต้มได้จนถึงวันที่ ',
    validUntil: 'จะใช้ได้ถึงวันที่ ',
    cardFromShop: 'บัตรสะสมแต้มของร้าน',
    alertMessage: 'ข้อความสำหรับแจ้งเตือนลูกค้า',
    cardSuspension: 'ระงับการใช้บัตรสะสม',
    cardSuspensionDate: 'วันระงับการใช้บัตร',
    endDateOfPoint: 'วันสิ้นสุดการแจกแต้ม',
    saveCardSettingDialog: 'คุณต้องการบันทึกการตั้งค่าบัตรสะสมแต้ม และ เปิดใช้งานบัตรหรือไม่?',
    saveDraftConfirmation: 'คุณต้องการบันทึกฉบับร่างหรือไม่?',
    saveAndActive: 'บันทึก และ เปิดใช้งาน',
    updateAndActive: 'อัพเดท และ เปิดใช้งาน',
    saveDraft: 'บันทึกฉบับร่าง',
    youSelected: 'คุณเลือกสินค้าแล้วจำนวน',
    selectProductForPointAccumulation: 'เลือกสินค้าที่ร่วมรายการสะสมแต้ม',
    getFree: 'แถมฟรี',
    inputFreeItem: 'กรอกจำนวนสินค้าที่ต้องการแถม...',
    purchaseAmount: 'ซื้อสินค้าครบจำนวน',
    suspendUsage: 'ระงับการใช้งาน',
    systemUserManual: 'คู่มือใช้งานระบบ',
    orCheckOutAt: 'หรือศึกษาการใช้งานระบบเพิ่มเติมได้จาก',
    youCanRegisterAt: 'สามารถลงทะเบียนร้านค้าได้ที่แถบ',
    noRegisterLineOA: 'คุณยังไม่ได้ลงทะเบียนร้านค้า (LINE OA)',
    cardSetting: 'ตั้งค่าบัตรสะสมแต้ม',
    cardSettingDialog: 'กำหนดเงื่อนไขการใช้งานบัตรสะสมแต้ม',
    createCardDialog: 'สามารถสร้างบัตรสะสมแต้มได้ที่เมนู',
    noCard: 'ไม่มีข้อมูลบัตรสะสมแต้ม',
    availableCrm: 'ยังไม่ใช้งาน',
    used: 'ใช้แล้ว',
    unused: 'ยังไม่ใช้',
    redeemRewards: 'แลกของรางวัล',
    noteCrm: 'หมายเหตุ',
    noteCardDialog1: 'ลูกค้าจะได้รับแต้มจากการซื้อสินค้าที่ร่วมรายการภายในร้าน',
    noteCardDialog2: 'ไม่สามารถใช้แทนเงินสดได้',
    noteCardDialog3: 'ไม่สามารถใช้ร่วมกับบัตรแลกของรางวัล ส่วนลด หรืออื่นๆ',
    noteCardDialog4: 'บัตรสะสมแต้มที่มีสถานะ "ใช้แล้ว" จะไม่สามารถใช้ได้อีก',
    noteCardDialog5: 'สามารถกดใช้สิทธิ์ได้โดยการกดบัตรสะสมแต้ม และยืนยันการใช้สิทธิ์',
    redeemFor: 'แลกรับฟรี',
    accomulateComplete: 'สะสมครบ',
    exampleOfRewardCard: 'ตัวอย่างบัตรสะสมแต้มสำหรับลูกค้า',
    draft: 'ฉบับร่าง',
    reward: 'ของรางวัล',
    createReward: 'สร้างของรางวัลเพื่อให้ลูกค้าสามารถแลกรับคูปองได้ที่นี่',
    createCoupon: 'สร้างคูปองของรางวัล',
    deletedCoupon: 'คูปองถูกลบออกจากระบบ',
    retrieveLoyaltyPoints: 'เรียกคืนคะแนนสะสม',
    retrieveLoyaltyPointsDialog: 'ค้นหาสมาชิก และเรียกคืนคะแนนสะสมรายสมาชิก',
    sendLoyaltyPoints: 'นำเข้าคะแนนสะสม',
    sendLoyaltyPointsDialog: 'ค้นหาสมาชิก และนำเข้าคะแนนสะสมรายสมาชิก',
    searchMemberDialog: 'ค้นหาสมาชิกด้วยชื่อ หรือเบอร์โทรศัพท์',
    searchMemberCRM: 'ค้นหาสมาชิก',
    pointAmount: 'จำนวนคะแนน',
    sendPoint: 'ส่งคะแนนสะสม',
    youWantToAdd: 'คุณต้องการเพิ่ม',
    youWantToRetrive: 'คุณต้องการเรียกคืน',
    toCrm: 'ให้แก่',
    nameCrm: 'ชื่อ',
    inputPoint: 'กรอกจำนวนคะแนน',
    rewardFromPointsAccumulation: 'ของรางวัลสะสมคะแนน',
    transactionStatus: 'สถานะการทำรายการ',
    pointRecive: 'คะแนนที่ได้รับ',
    amount: 'ยอดซื้อ',
    today: 'วันนี้',
    yesterday: 'เมื่อวาน',
    last7days: '7 วันล่าสุด',
    last30days: '30 วันล่าสุด',
    thismonth: 'เดือนนี้',
    lastmonth: 'เดือนที่แล้ว',
    transection: 'ประวัติการทำรายการ',
    comingSoon: 'เร็ว ๆ นี้',
    loyaltyCard: 'บัตรสะสมแต้ม',
    welcomeMessage: 'ยินดีต้อนรับเข้าสู่ระบบ',
    messageSent: 'ดำเนินการเรียบร้อย',
    outOfStock: 'สินค้าหมด',
    QRCode: 'คิวอาร์ โค้ด',
    GenerateQRCode: 'สร้างคิวอาร์โค้ด',
    ForQROrder: 'สำหรับสั่งอาหาร และรอรับบริการภายในร้านค้า',
    QROrderDescription: 'คุณสามารถสร้างคิวอาร์โค้ด สำหรับให้ลูกค้าสแกนสั่งอาหาร และรอรับบริการภายในร้านค้า',
    SelectTable: 'เลือกโต๊ะ และระบุจำนวนลูกค้า',
    numberOfCustomer: 'จำนวนลูกค้า (คน)',
    numberOfCustomerDescription: 'ระบุจำนวนลูกค้าอย่างน้อย 1 คน',
    currentUser: 'บัญชีผู้ใช้',
    guest: 'ท่าน',
    orderItem: 'รายการที่สั่ง',
    callStaff: 'เรียกพนักงาน',
    callStaffDialog: 'ลูกค้าต้องการความช่วยเหลือด้านใด?',
    cutlery: 'ขออุปกรณ์',
    seasoning: 'ขอเครื่องปรุง',
    refillDrink: 'เติมเครื่องดื่ม',
    startOrder: 'เริ่มคำสั่งซื้อ',
    other: 'อื่น ๆ',
    tableNumber: 'หมายเลขโต๊ะ',
    menuList: 'หน้าหลัก',
    selectedItem: 'รายการที่เลือก',
    foodCategory: 'หมวดหมู่อาหาร',
    searchByName: 'ค้นหาสินค้าโดยชื่อ...',
    noPreduct: 'ไม่มีรายการสินค้า',
    noSelectedProduct: 'ไม่มีสินค้าในรายการที่คุณเลือก',
    backToMenu: 'กลับไปทำรายการ',
    removeSelected: 'คุณยืนยันที่จะลบรายการที่เลือก?',
    confirmOrder: 'ยืนยันรายการ',
    endOfTransection: 'สิ้นสุดการทำรายการ',
    endOfTransectionDialog: 'กรณีเกิดปัญหาการใช้งาน กรุณาติดต่อพนักงาน',
    note: 'โน๊ต',
    orderTime: 'เวลาสั่งซื้อ',
    orderMoreFood: 'สั่งอาหารเพิ่ม',
    login: 'เข้าสู่ระบบ',
    loginWithGmail: 'เข้าสู่ระบบด้วย Gmail',
    forgetPassword: 'ลืมรหัสผ่าน',
    or: 'หรือ',
    loginAsStaff: 'เข้าสู่ระบบโดยรหัสพนักงาน',
    billOrder: 'ใบสั่งอาหาร',
    table: 'โต๊ะ',
    billOrderDialog: 'สแกน QR Code เพื่อดูเมนู และสั่งอาหาร',
    billOrderDialog2: 'หากท่านติดปัญหาการใช้งานโปรดแจ้งพนักงาน',
    selectTableAleart: 'กรุณาเลือกโต๊ะก่อนสร้างคิวอาร์โค้ด',
    emptyCartDialog: 'ยังไม่มีการทำรายการ',
    served: 'เสิร์ฟแล้ว',
    contactOurStaff: 'ติดต่อพนักงานของเรา',
    addToCart: 'หยิบใส่ตะกร้า',
    noteToShop: 'ข้อความถึงร้านค้า',
    addYourRequest: 'เพิ่มข้อความถึงร้านค้า',
    reporttxt: 'รายงาน',
    general: 'ทั่วไป',
    shopname: 'ชื่อร้าน',
    branchName: 'สาขา',
    businessType: 'ประเภทธุรกิจ',
    taxID: 'เลขประจำตัวผู้เสียภาษี',
    vat: 'ภาษีมูลค่าเพิ่ม',
    exclusive: 'รวม VAT',
    inclusive: 'ไม่รวม VAT',
    serviceCharge: 'ค่าบริการ',
    address: 'ที่อยู่',
    address1: 'ที่อยู่บรรทัดที่ 1',
    address2: 'ที่อยู่บรรทัดที่ 2',
    tel: 'เบอร์โทรศัพท์',
    shopopen: 'เวลาเปิด-ปิดร้าน',
    open: 'เปิด',
    closed: 'ปิด',
    languagetxt: 'ภาษา',
    language: 'ภาษา และโซนเวลา',
    languageTH: 'ไทย',
    languageEN: 'อังกฤษ',
    setting: 'การตั้งค่า',
    shop: 'ร้านค้า',
    timezone: 'โซนเวลา',
    deliveryProviders: 'รายชื่อผู้ให้บริการเดลิเวอรี่',
    adddeliveryProviders: 'เพิ่มผู้ให้บริการ',
    lastUpdate: 'วันที่อัพเดต',
    logo: 'โลโก้',
    businessType1: 'ร้านค้าทั่วไป',
    businessType2: 'ร้านอาหาร',
    businessType3: 'โฮสเทล',
    updateData: 'อัพเดทข้อมูล',
    submit: 'ตกลง',
    cancel: 'ยกเลิก',
    normal: 'ปกติ',
    loading: 'กำลังโหลด...',
    editing: 'กำลังแก้ไข',
    confirmUpdate: 'คุณต้องการแก้ไขข้อมูลหรือไม่',
    selectedShoptype: 'เลือกประเภทร้าน...',
    selectshop: 'เลือกสาขา',
    save: 'บันทึก',
    signOut: 'ออกจากระบบ',
    unit: 'หน่วยบรรจุ',
    category: 'กลุ่มสินค้า',
    product: 'สินค้า',
    export: 'ส่งออกไฟล์',
    exportKBank: 'ส่งออกไฟล์ K-Bank',
    date: 'วันที่',
    to: 'ถึง',
    status: 'สถานะ',
    vatAmount: 'คิดเป็นมูลค่าภาษี',
    netTotalNonVAT: 'สินค้าไม่มีภาษี',
    receiptNumber: 'เลขที่ใบกำกับ',
    grandTotal: 'จำนวนเงิน',
    netAmountVATTotal: 'มูลค่าสินค้าก่อน VAT',
    netTotalIncVAT: 'สินค้ามีภาษี',
    proofOfPayment: 'หลักฐานการชำระเงิน',
    paymentType: 'ประเภทการชำระ',
    paymentType0: 'เงินสด',
    paymentType1: 'บัตรเครดิต',
    paymentType2: 'คูปอง',
    paymentType3: 'Quickpay',
    paymentType4: 'โอน(พร้อมเพย์)',
    paymentType5: 'Alipay',
    paymentType6: 'มัดจำ',
    paymentType7: 'Delivery',
    paymentType8: 'Online',
    paymentType9: 'Line Pay',
    paymentType10: 'อื่นๆ',
    paymentType12: 'KBank',
    payment: 'การชำระเงิน',
    paid: 'มูลค่าการชำระ',
    change: 'เงินทอน',
    sentSuccessfully: 'ส่งแล้ว',
    pluCode: 'รหัสสินค้า',
    productName: 'ชื่อสินค้า',
    quantity: 'จำนวน',
    priceUnit: ' ราคา/หน่วย',
    discount: 'ส่วนลด',
    timeOfOrder: 'เวลาที่สั่งซื้อ',
    orderDate: 'สั่งซื้อเมื่อ',
    preparing: 'เวลาเตรียมสินค้า',
    paymentDetail: 'รายละเอียดการขาย',
    orderNumber: 'หมายเลขคำสั่งซื้อ',
    delivered: 'กำหนดส่ง',
    deliveredDate: 'เวลาส่ง',
    baht: 'บาท',
    profit: 'กำไร',
    sales: 'ยอดขาย',
    billAmount: 'จำนวนบิล',
    total: 'ทั้งหมด',
    saleAmount: 'ยอดสั่งซื้อ',
    bill: 'บิล',
    discountAmount: 'ส่วนลดท้ายบิล',
    netAmount: 'ยอดขายก่อนลด',
    averageBill: 'เฉลี่ย/บิล',
    voidbillTotal: 'มูลค่าการยกเลิก',
    salesByDaily: 'ยอดขายรายวัน',
    salesByMonth: 'ยอดขายรายเดือน',
    bestSellers: 'สินค้าขายดี',
    bestSellerstype: 'ประเภทสินค้าขายดี',
    salesBydate: 'ยอดขายแยกตามช่วงเวลา',
    number: 'ลำดับ',
    categoryName: 'ชื่อประเภท',
    newOrder: 'คำสั่งซื้อใหม่',
    reject: 'ปฏิเสธ',
    rejectTime: 'เวลาที่ปฏิเสธ',
    inProgressTime: 'เวลาเตรียมสินค้า',
    cancelTime: 'เวลาที่ยกเลิก',
    cause: 'เหตุผลที่ยกเลิก',
    preparingToShip: 'กำลังเตรียมสินค้า',
    totalAmount: 'ยอดสุทธิ',
    order: 'คำสั่งซื้อ',
    deposit: 'มัดจำ',
    billSave: 'พักบิล',
    ReceivingOrder: 'เปิดโต๊ะ/กำลังรับออเดอร์',
    orderNo: 'เลขที่รายการ',
    remark: 'หมายเหตุท้ายบิล',
    receiptStatus: 'สถานะ',
    back: 'กลับ',
    detail: 'รายละเอียด',
    countSales: 'ขายได้จำนวน',
    onhandQty: 'คงเหลือ',
    stdCost: 'ราคาทุน',
    employees: 'พนักงาน',
    employeesname: 'ชื่อพนักงาน',
    salesTotal: 'ยอดขายทั้งหมด',
    totalBill: 'บิลทั้งหมด',
    billActive: 'บิลที่ขาย',
    billVoice: 'บิลยกเลิก',
    billDeposit: 'บิลมัดจำ',
    discountItem: 'ส่วนลดในรายการ',
    billDiscountAmount: 'ส่วนลดท้ายบิล',
    billDiscount: 'บิลที่ทำส่วนลด',
    payTotal: 'ชำระรวม',
    deliveryProvider: 'ประเภท Delivery',
    deliveryDetail: 'รายละเอียดรายการ Delivery',
    customDetail: 'รายละเอียดรายการจ่ายเงินอื่นๆ',
    customPaymentName: 'ประเถทรายการ',
    firstInvoiceNO: 'เลขที่บิลเริ่มต้น',
    lastInvoiceNO: 'เลขที่บิลสุดท้าย',
    roundNo: 'รอบการขายที่',
    closeAt: 'เวลาปิดรอบการขาย',
    closeBy: 'ปิดรอบขายโดย',
    totalCashSales: 'ยอดขายด้วยเงินสด',
    initialChange: 'เงินทอนเริ่มต้น',
    totalCashIn: 'เงินเข้า',
    totalCashOut: 'เงินออก',
    actualInDrawer: 'จำนวนเงินที่นับได้ในลิ้นชัก',
    expectedInDrawer: 'จำนวนเงินที่ควรมีในลิ้นชัก',
    difference: 'ส่วนต่าง',
    voidBillDetail: 'รายละเอียดบิลยกเลิกการขาย',
    cancelBy: 'ยกเลิกโดย',
    cancelDate: 'ยกเลิกเวลา',
    searchByEmployees: 'ค้นหาพนักงาน...',
    search: 'ค้นหา',
    timeIn: 'เวลาเข้างาน',
    timeOut: 'เวลาออกงาน',
    time: 'ชั่วโมงการทำงาน',
    hours: 'ชั่วโมง',
    minute: 'นาที',
    netTotal: 'ยอดเงินสุทธิ',
    reason: 'หมายเหตุ',
    searchByProduct: 'ค้นหาจากชื่อสินค้า ...',
    sortBy: 'เรียงโดย',
    last: 'ล่าสุด',
    price: 'ราคาสินค้า',
    sortByNumMin: 'ราคาน้อยไปมาก',
    sortByNumMax: 'ราคามากไปน้อย',
    documentNo: 'เลขที่เอกสาร',
    transactionType: 'ประเภท',
    updatedBy: 'ปรับปรุงโดย',
    transactionType1: 'ขายออก',
    transactionType2: 'รับคืนจากลูกค้า',
    transactionType3: 'รับสินค้าเข้า',
    transactionType4: 'จ่ายสินค้าออก',
    transactionType5: 'ปรับปรุงเพิ่ม',
    transactionType6: 'ปรับปรุงลด',
    transactionType7: 'แลกด้วยแต้ม',
    transactionType8: 'ลบ-รับเข้า',
    transactionType9: 'ลบ-จ่ายออก',
    transactionType10: 'แก้ไข-รับเข้า',
    transactionType11: 'แก้ไข-จ่ายออก',
    billNo: 'เลขที่ในบิลซื้อ',
    docTotal: 'ยอดรวม',
    customer: 'ผู้จำหน่าย',
    username: 'ชื่อผู้ใช้',
    dateOfBill: 'วันที่ซื้อในบิล',
    selectDate: 'เลือกวันที่',
    dialogSelectCategory: 'กรุณาเลือกหมวดหมู่การค้นหา',
    dialogUpdateDocumentSuccess: 'แก้ไขเอกสารเรียบร้อยแล้ว',
    dialogfound: 'กรอกข้อมูลไม่ถูกต้อง',
    isVatIncluded: 'สินค้ารวมภาษีแล้ว',
    vatIccludes: 'คิดภาษีมูลค่าเพิ่ม',
    confirmStock: 'ยืนยันการซื้อ',
    confirm: 'ยืนยัน',
    deleteDoc: 'ลบเอกสาร',
    confirmUpdateDocModal: 'คุณต้องการบันทึกการแก้ไขเอกสารหรือไม่ ?',
    confirmSaveDoc: 'ยืนยันการบันทึกเอกสาร',
    confirmDeleteDoc: 'ยืนยันการลบเอกสาร',
    confirmDeleteModal: 'คุณต้องการลบเอกสารหรือไม่',
    dialogSaveDocumentSuccess: 'สร้างเอกสารรับสินค้าเข้าเรียบร้อยแล้ว',
    createStockIn: 'สร้างเอกสารรับสินค้าเข้า',
    confirmSaveDocModal: 'คุณต้องการบันทึกเอกสารรับเข้าหรือไม่ ?',
    selectShopPlease: 'กรุณาเลือกร้านค้า',
    selectProductPlease: 'กรุณาเลือกสินค้า',
    duplicateProduct: 'คุณเลือกรายการสินค้านี้แล้ว',
    productInvilid: 'รายการสินค้าที่คุณเลือกไม่ถูกต้อง กรุณาติดต่อผู้ดูแลระบบ',
    confirmStockOut: 'ยืนยันการจ่ายออก',
    dialogSaveDocumentOutSuccess: 'สร้างเอกสารจ่ายสินค้าออกเรียบร้อยแล้ว',
    confirmSaveDocStockOutModal: 'คุณต้องการบันทึกเอกสารจ่ายออกหรือไม่ ?',
    adjustDocType: 'ประเภทเอกสาร',
    adjustDocType1: 'ปรับปรุงเพิ่ม',
    adjustDocType2: 'ปรับปรุงลด',
    beforeAdjust: 'ก่อนปรับปรุง',
    afterAdjust: 'หลังปรับปรุง',
    checkCount: 'ยอดตรวจนับ',
    adjust: 'ปรับปรุง',
    createAdjustStock: 'สร้างเอกสารปรับปรุงสินค้า',
    confirmAdjust: 'ยืนยันการปรับปรุง',
    searhBy: 'ค้นหาสินค้าโดย',
    createDoucument: 'สร้างเอกสาร',
    transferType: 'ประเภทเอกสาร',
    transferType1: 'เอกสารรับเข้า',
    transferType2: 'เอกสารจ่ายออก',
    new: 'ใหม่',
    finish: 'สำเร็จ',
    refDocument: 'อ้างอิงจากเอกสาร',
    statusDoc: 'สถานะเอกสาร',
    dialogSaveTransferDocumentSuccess:
        'สร้างเอกสารจ่ายสินค้าระหว่างสาขาเรียบร้อยแล้ว',
    destinationShop: 'ร้านค้าปลายทาง',
    destinationShopBranch: 'สาขาปลายทาง',
    sourceBranch: 'สาขาต้นทาง',
    sourceShop: 'ร้านค้าต้นทาง',
    sourceAddress: 'ที่อยู่สาขาต้นทาง',
    destinationAddress: 'ที่อยู่สาขาปลายทาง',
    saveTransfer: 'บันทึกเอกสารการโอน',
    confirmSaveTransfer: 'คุณต้องการบันทึกการโอนสินค้าระหว่างสาขาหรือไม่',
    savingDoc: 'กำลังบันทึกเอกสาร',
    selectdestinationPlease: 'กรุณาเลือกสาขาปลายทาง',
    validatedestinationproduct: 'สาขาปลายทางไม่มีสินค้า',
    validateProduct: 'กรุณาเพิ่มสินค้า',
    created_by: 'โดย',
    receive: 'รับ',
    updateDoc: 'แก้ไขเอกสาร',
    cancelDoc: 'ยกเลิกเอกสาร',
    confirmCancelDoc: 'คุณต้องการยกเลิกเอกสารหรือไม่',
    cancelingDoc: 'กำลังยกเลิกเอกสาร',
    updatingDoc: 'กำลังแก้ไขเอกสาร',
    updateDocFail: 'ไม่สามารถแก้ไขเอกสารได้',
    cancelDocSuccess: 'ยกเลิกเอกสารสำเร็จ',
    cancelDocFail: 'ไม่สามารถยกเลิกเอกสารได้',
    refDocumentTransfer: 'เลขเอกสารอ้างอิงจากต้นทาง',
    comfrimTransferIn: 'ยืนยันการรับสินค้า',
    dilogcomfrimTransferIn: 'คุณต้องการยืนยันการรับสินค้าหรือไม่',
    transferInDetail: 'รายละเอียดสินค้ารับเข้า',
    totalNet: 'รวมมูลค่าสินค้า',
    createStockOut: 'สร้างเอกสารจ่ายสินค้าออก',
    favorite: 'สินค้า-นิยม',
    isOnScreen: 'แสดงบนหน้าขาย',
    negotiatePrice: 'ราคาด่วน', //
    productTypeP: 'สินค้าทั่วไป',
    productTypeBOM: 'สินค้าประกอบ (BOM)',
    productTypeSN: 'สินค้ามี Serial',
    productTypeSV: 'สินค้าบริการ (สินค้าไม่มีสต๊อก)',
    productPLU: 'ขนาดบรรจุอื่นๆ',
    productBOM: 'ส่วนประกอบ',
    productSN: 'หมายเลขผลิตภัณฑ์',
    morePrice: 'ราคาขายเพิ่มเติม',
    cost: 'ต้นทุน',
    quantityPrice: 'จำนวน/ราคาขาย',
    exceedUc: 'ราคาขาย',
    changeImg: 'เปลี่ยนรูปภาพ',
    addProduct: 'เพิ่มสินค้า',
    selectImg: 'เลือกรูปภาพ',
    deleteImg: 'ลบรูปภาพ',
    selectImgFromPC: 'เลือกรูปภาพจากเครื่อง',
    confirmSaveImgDialog: 'คุณต้องการบันทึกสินค้าหรือไม่',
    confirmSaveImg: 'ยืนยันการบันทึกสินค้า',
    addPrice: 'เพิ่มราคาขาย',
    editPrice: 'แก้ไขราคาขาย',
    dialigDeleteProduct: 'คุณต้องการลบสินค้าหรือไม่',
    confirmDeleteProduct: 'ยืนยันการลบสินค้า',
    productType: 'ประเภทสินค้า',
    countProduct: 'สินค้าทั้งหมด',
    addUnit: 'เพิ่มหน่วยบรรจุ',
    editUnit: 'แก้ไขหน่วยบรรจุ',
    active: 'ใช้งาน',
    inactive: 'ไม่ใช้งาน',
    confirmSaveUnitDialog: 'คุณต้องการบันทึกหน่วยสินค้าหรือไม่',
    confirmSaveUnit: 'ยืนยันการบันทึกหน่วยสินค้า',
    dialigDeleteUnit: 'คุณต้องการลบหน่วยของสินค้าหรือไม่',
    confirmDeletUnit: 'ยืนยันการลบหน่วยสินค้า',
    confirmSaveCategoryDialog: 'คุณต้องการบันทึกกลุ่มสินค้าหรือไม่',
    confirmSaveCategory: 'ยืนยันการบันทึกกลุ่มสินค้าา',
    dialigDeleteCategory: 'ยืนยันการลบกลุ่มสินค้า',
    confirmDeletCategory: 'คุณแน่ใจหรือไม่ว่าคุณต้องการลบกลุ่มสินค้า',
    addCategory: 'เพิ่มกลุ่มสินค้า',
    editCategory: 'แก้ไขกลุ่มสินค้า',
    bgColor: 'สีพื้นหลัง',
    selectbgColor: 'เลือกสีพื้นหลัง',
    validateCategoryname: 'กรุณากรอกชื่อกลุ่มสินค้า',
    sequence: 'ลำดับการแสดงข้อมูล',
    enterProduct: 'กรุณากรอกชื่อสินค้า',
    enterPrice: 'กรุณากรอกราคาสินค้า',
    selectCategory: 'กรุณาเลือกกลุ่มสินค้า',
    selectUnit: 'เลือกหน่วยสินค้า',
    confirmSaveProduct: 'คุณต้องการบันทึกสินค้าหรือไม่?',
    confirmSaveProductPrice: 'คุณต้องการบันทึกราคาสินค้าเพิ่มเติมหรือไม่',
    validatepackQty: 'กรุณากรอก จำนวน/ราคาขาย',
    validatepackUc: 'กรุณากรอก ราคาขาย',
    validatePRU: 'ข้อมูลราคาขายเพิ่มเติมนี้มีอยู่ กรุณากรอกใหม่อีกครั้ง',
    createProductSuccess: 'สร้างสินค้าเรียบร้อยแล้ว',
    addSerial: 'เพิ่มหมายเลขผลิตภัณฑ์',
    add: 'เพิ่ม',
    createSerial: 'สร้าง Serial อัตโนมัติ',
    available: 'พร้อมจำหน่าย',
    soldOut: 'จำหน่ายแล้ว',
    deleteSuccess: 'ลบข้อมูลสำเร็จ ',
    enterSerial: 'กรุณากรอก Serial Number',
    confirmSaveSerial: 'คุณต้องการบันทึกหมายเลข Serial number หรือไม่?',
    confirmDelete: 'คุณต้องการลบ Serial number นี้หรือไม่?',
    PLU: 'ขนาดบรรจุ',
    addProductPLU: 'เพิ่มขนาดบรรจุอื่นๆ',
    img: 'รูปภาพ',
    SKURatio: 'ตัดสต็อก',
    editProductPLU: 'แก้ไขขนาดบรรจุ',
    enterSKURatio: 'กรุณากรอกสต็อกสินค้า',
    comfrimDeletePLU: 'คุณต้องลบขนาดบรรจุหรือไม่?',
    useStepPrice: 'ใช้ราคาขายเพิ่มเติมเมื่อถึงจำนวนที่กำหนดไว้',
    addBOM: 'เพิ่มส่วนประกอบ',
    confirmSaveBOM: 'คุณต้องการบันทึกหรือไม่?',
    user: 'บัญชีร้านค้า',
    userDetail: 'ข้อมูลผู้ใช้',
    planDetail: 'รายละเอียดแพ็คเกจ',
    currentPlan: 'แผนบริการ',
    expireDate: 'เริ่มใช้งานเมื่อ',
    startDate: 'เริ่มใช้งานเมื่อ',
    dialogSignOut: 'คุณต้องการออกจากระบบใช่หรือไม่?',
    confirmSignOut: 'กรุณากดตกลงเพื่อออกจากระบบ',
    countMember: 'สมาชิกทั้งหมด',
    addMenber: 'เพิ่มสมาชิก',
    searchMember: 'ค้นหาโดยชื่อ อีเมล์ หรือเบอร์โทรศัพท์',
    dateOfMember: 'เยี่ยมชมครั้งแรก',
    recentVisit: 'เยี่ยมชมครั้งล่าสุด',
    memberName: 'ชื่อสมาชิก',
    email: 'อีเมล',
    paymentTotal: 'ใช้จ่ายสะสม',
    point: 'แต้มสะสม',
    pointtxt: 'คะแนนปัจจุบัน',
    postalCode: 'รหัสไปรษณีย์',
    saveing: 'กำลังบันทึก . . .',
    edit: 'แก้ไข',
    editMember: 'แก้ไขข้อมูลสมาชิก',
    deleteing: 'กำลังลบ . . .',
    dialogDeleteMember: 'คุณต้องการลบสมาชิก',
    confirmDeleteMember: 'ยืนยันการลบสมาชิก',
    valtdateMember: 'กรุณากรอกข้อมูลให้ครบ',
    createMemberSuccess: 'เพิ่มสมาชิกสำเร็จ !!',
    updateMemberSuccess: 'แก้ไขข้อมูลสมาชิกสำเร็จ !!',
    deleteMemberSuccess: 'ลบข้อมูลสมาชิกสำเร็จ !!',
    onhandQtyMin: 'จำนวนน้อยไปมาก',
    onhandQtyMax: 'จำนวนมากไปน้อย',
    onhandQtySort: 'จำนวนสินค้า',
    onhandQtySortPlaceholder: 'กรอกจำนวนสินค้า ...',
    perUnit: 'ต่อหน่วย',
    showProductAll: 'สินค้าทั้งหมด',
    updateAt: 'ปรับปรุงล่าสุด',
    sortName: 'ชื่อสินค้า',
    stockUnit: 'หน่วยละ',
    selectd: 'เลือก',
    searchProduct: 'ค้นหาสินค้า',
    reset: 'รีเซต',
    selectdProduct: 'เลือกสินค้า',
    information: 'ข้อมูลเบื้องต้น',
    adjustStockList: 'รายการปรับปรุงสต๊อก',
    adjustDetail:
        'เพิ่มสินค้าลงรายการปรับแรุงสต๊อก และตั้งค่ายอดตรวจนับของสินค้าในแต่ละชิ้น',
    transferoutList: 'รายการโอนสินค้าระหว่างสาขา',
    addProductTransfer:
        'เพิ่มสินค้าลงรายการโอนสินค้าระหว่างสาขา และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    transferDetail: 'รายการโอนสินค้าระหว่างสาขา และส่วนลดของสินค้าในแต่ละชิ้น',
    transferinList:
        'รายการสินค้ารับเข้าระหว่างสาขา และรายการส่วนลดของสินค้าในแต่ละชิ้น',
    stockOutList: 'รายการสินค้าจ่ายออก',
    addstockOutList:
        'เพิ่มสินค้าลงรายการจ่ายออก และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    stockinList: 'รายการสินค้ารับเข้า',
    addProductStockin:
        'เพิ่มสินค้าลงรายการรับเข้า และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    deleteCategory: 'ลบกลุ่มสินค้า',
    item: 'รายการ',
    from: 'จาก',
    service: 'บริการ',
    resource: 'ทรัพยากร',
    bookingNumber: 'หมายเลขการจอง',
    days: 'วัน',
    mounth: 'เดือน',
    weeks: 'สัปดาห์',
    years: 'ปี',
    dashboardBook: 'รายการจอง',
    resourceType: 'ประเภททรัพยากร',
    categoryBooking: 'หมวดหมู่',
    memberDetail: 'ข้อมูลสมาชิก',
    package: 'รายละเอียดแพ็กเกจ ',
    startPackate: 'เริ่มใช้งานเมื่อ',
    endPackate: 'ใช้งานได้ถึง',
    name: 'ชื่อสินค้า',
    productImg: 'ภาพสินค้า',
    fistname: 'ชื่อ',
    lastname: 'สกุล',
    saveInformation: 'บันทึกข้อมูล',
    confirmSaveMember: 'คุณต้องการยืนยันการบันทึกข้อมูลใช่หรือไม่',
    yesOrNo: 'ใช่หรือไม่',
    totaValue: 'มูลค่ารวม',
    deleteMember: 'ลบสมาชิก',
    frequently: 'สินค้าที่ซื้อบ่อย',
    recentPurchase: 'การซื้อล่าสุด',
    paymentDescription: 'รายละเอียดการขาย',
    validateInfomation: 'กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน',
    validateTel: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
    validateEmail: 'อีเมล์ไม่ถูกต้อง',
    saveSuccess: 'บันทึกข้อมูลสำเร็จ',
    txtcustomer: 'รายชื่อลูกค้า',
    customerName: 'ชื่อลูกค้า',
    customerCount: 'ลูกค้าทั้งหมด',
    addCustomer: 'เพิ่มลูกค้า',
    permissionManage: 'สิทธิการเข้าถึง',
    mainMenu: 'เมนูหลัก',
    page: 'หน้า',
    show: 'แสดง',
    delete: 'ลบ',
    administratorTools: 'เครื่องมือสำหรับผู้ดูแลระบบ',
    deviceName: 'ชื่ออุปกรณ์',
    lastSyncProduct: 'เวลาใช้งานล่าสุด',
    version: 'เวอร์ชั่น',
    posstatus: 'สถานะการเชื่อมต่อกับระบบ',
    posNumber: ' หมายเลขเครื่อง POS',
    titleSilomconnect: 'ไม่พลาดทุกยอดขายด้วยบริการจาก',
    updateSilom: 'อัปเดตยอดขายรายวันผ่าน LINE ฟรี',
    addfriend: 'เพิ่มเพื่อน',
    enterToReciveService: 'กดรับรหัสสำหรับใช้บริการ',
    verifyCodeToUsed:
        'เพื่อเริ่มต้นใช้งานระบบ คุณสามารถกดรับรหัสเพื่อใช้ในการยืนยันตัวตน',
    verifyfivemin: 'รหัสยืนยันสามารถใช้ได้ภายใน 5 นาที',
    yourVertify: 'รหัสของคุณคือ',
    pressCode: 'กดรับรหัส',
    exCode: 'รหัสหมดอายุภายใน',
    sessionEx: 'เซสชันการเข้าสู่ระบบหมดอายุ กรุณาล็อกอินใหม่อีกครั้ง',
    refresh: 'รีเฟรช',
    selectedFile: 'เลือกไฟล์',
    fileSize: 'ขนาดไฟล์แนะนำ ไม่เกิน 2 MB',
    isBeingToUsed: 'กำลังถูกใช้งาน',
    notInUse: 'ว่างจากการใช้งาน',
    confirmUpdateInformation: 'ยืนยันการอัพเดพข้อมูล',
    memberCount: 'สมาชิกทั้งหมด',
    orderMachine: 'เครื่อง Order',
    textMember: 'รายชื่อสมาชิก',
    manageShop: 'จัดการข้อมูลร้านค้าเบื้องต้น',
    lansetting: 'ตั้งค่าภาษา และโซนเวลาเบื้องต้นสำหรับร้านค้า',
    timezoneSetiing: 'ตั้งค่าโซนเวลาสหรับร้านค้าเบื้องต้น',
    deliveryProviderSetting: 'รายชื่อผู้ให้บริการเดลิเวอรี่ สำหรับร้านค้า',
    toolsSetting: 'จัดการเครื่อง POS สำหรับร้านค้า',
    checkStockPLU: 'สินค้าคงเหลือ < 0',
    searching: 'กำลังค้นหา',
    allProduct: 'สินค้าทั้งหมด',
    foundProductOnhand: 'พบจำนวนคงเหลือในขนาดบรรจุอื่นคงเหลือน้อยกว่า 0',
    confirmAdjustBefor: 'ท่านต้องการให้ระบบปรับปรุงอัตโนมัติหรือไม่',
    requireAdjust: 'ต้องการ',
    imrequireAdjust: 'ไม่ต้องการ',
    islimitItemTodoc: 'เลือกรายการสินค้าได้ไม่เกิน 200 รายการ / 1 เอกสาร',
    barcodePrint: 'พิมพ์บาร์โค้ด',
    printEx: 'ตัวอย่าง & พิมพ์',
    print: 'พิมพ์',
    downloadAndExport: 'ดาวน์โหลดและพิมพ์',
    selectFormat: 'เลือกรูปแบบกระดาษ',
    peperFormat: 'รูปแบบกระดาษ', //รูปแบบกระดาษ
    optional: 'ตัวเลือกเสริม', //Optional
    standardA427: 'มาตรฐาน A4 : 27 รายการ / แผ่น',
    standardA450: 'มาตรฐาน A4 : 52 รายการ / แผ่น',
    standardA460: 'มาตรฐาน A4 : 60 รายการ / แผ่น',
    standardA556: 'มาตรฐาน A4  : 56 รายการ / แผ่น (สติ๊กเกอร์ขนาด A5)',
    standardA550: 'ขนาดกระดาษ A5 : 52 รายการ / แผ่น',
    standardA560: 'ขนาดกระดาษ A5 : 60 รายการ / แผ่น',
    countBarcode: 'จำนวนบาร์โค้ด',
    barcode: 'รหัสบาร์โค้ด',
    setFontsize: 'กำหนดตัวอักษร',
    accessPermissionDes: 'กำหนดสิทธิการเข้าถึงสำหรับผู้ใช้',
    deleteUser: 'ยืนยันการลบผู้ใช้',
    confirmDeleteUser: 'คุณต้องการลบผู้ใช้นี้ หรือไม่?',
    confirmUpdateUserInfo: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่?',
    passAtless: 'รหัสควรมีความยาวอย่างน้อย 6 ตัวอักษร',
    inValidPass: 'รหัสผ่านไม่ถูกต้อง',
    createBy: 'สร้างโดย',
    adjustAutoDes:
        'หากไม่ต้องการให้ระบบปรับปรุงอัตโนมัติ อาจทำให้ยอดจำนวนคงเหลือรวมสินค้าผิดพลาดได้',
    addUser: 'เพิ่มผู้ใช้',
    isMenu: 'แสดงบนเมนูลูกค้า',
    menuSmart: 'จัดการเมนูสินค้า',
    smartSettingTitle: 'ตั้งค่าเมนูสินค้า',
    smartSetting: 'ตั้งค่าเมนูสินค้า สำหรับระบบสมาชิกสะสมแต้ม - CRM ',
    confirmEnable: 'ท่านต้องการเปิดการใช้งานเมนูสินค้าหรือไม่',
    confirmDisEnable: 'ท่านต้องการปิดการใช้งานเมนูสินค้าหรือไม่',
    confirmSelfOrderEnable: 'ท่านต้องการเปิดการใช้งานสั่งซื้อด้วยตนเองหรือไม่',
    confirmSelfOrderDisable: 'ท่านต้องการปิดการใช้งานสั่งซื้อด้วยตนเองหรือไม่',
    enable: 'เปิดใช้งาน',
    disable: 'ปิดใช้งาน',
    usabilityStatus: 'สถานะการใช้งาน',
    manageShopDes: 'ท่านสามารถดูคู่มือการใช้งานได้',
    manageSmartOnProduct:
        'ท่านสามารถ "เพิ่ม/แก้ไข" รายการสินค้าที่ต้องการแสดงบนเมนูลูกค้ได้ที่ หน้า',
    manageSmartOnCategory:
        'ท่านสามารถ "เพิ่ม/แก้ไข" กลุ่มสินที่ต้องการแสดงบนเมนูลูกค้าได้ที่ หน้า',
    turnonSmart: 'และเลือกแสดงบนเมนูลูกค้า',
    desSmartMenu:
        'การตั้งค่าเมนู สำหรับแสดงสินค้าในรูปแบบ e-menu แสดงบน Line  เพื่อให้ลูกค้าเข้าถึงได้ง่ายขึ้น และสามารถสั่งสินค้าด้วยตนเองได้',
    link: 'ที่นี่',
    createTranferOut: 'สร้างเอกสารโอนสินค้าออก',
    roundingValue: 'ปัดเศษ',
    unRoundingValue: 'ไม่ปัดเศษ',
    limitItems: 'สามารถเพิ่มสินค้าได้ไม่เกิน 200 รายการ',
    IncomeCategory: 'รายรับ',
    ExpenseCategory: 'รายจ่าย',
    saveBy: 'บันทึกโดย',
    saveDate: 'วันทึกวันที่',
    drawerCate: 'หมวดหมู่',
    openingHours: 'เวลาเปิด-ปิดร้าน',
    shopLocation: 'ตำแหน่งร้านค้า',
    sunday: 'วันอาทิตย์',
    monday: 'วันจันทร์',
    tuesday: 'วันอังคาร',
    wednesday: 'วันพุธ',
    thursday: 'วันพฤหัสบดี',
    friday: 'วันศุกร์',
    saturday: 'วันเสาร์',
    lat: 'ละติจูด',
    lng: 'ลองจิจูด',
    shopCoverSetting: 'ตั้งค่าหน้าปก',
    shopCoverSettingDes: 'ตั้งค่าภาพหน้าปกร้านค้า เพื่อแสดงผลฝั่งลูกค้า',
    locationSettingDes: 'ระบุตำแหน่งที่ตั้งของร้านค้า',
    updatelocation: 'อัพเดตตำแหน่งที่ตั้งร้านค้า', //Update location
    searchLocation: 'ค้นหาตำแหน่ง', //Search Location
    imaginaryLine: 'ระบุพิกัด', //Imaginary Line
    openingHoursDes: 'ตั้งค่ากำหนดเวลาเปิดปิดร้าน สำหรับร้านค้า',
    paymentDescriptionConfig: 'เลือกประเภทการชำระเงินสำหรับร้านค้า',
    creditCard: 'บัตรเครดิต/เดบิต',
    promptPay: 'โอน (พร้อมเพย์)',
    coupon: 'คูปอง',
    quickpay: ' Krungsri Quick Pay',
    alipay: 'Alipay',
    linepay: 'Rabbit LINE Pay',
    thaidotcom: 'Thai Dot Com Payment',
    dayMY: ' วัน/เดือน/ปี',
    byStockIn: 'ชื้อสินค้า',
    stockOutOther: 'ค่าใช้จ่ายอื่นๆ',
    drawerSales: 'ขายสินค้า',
    loss: 'ขาดทุน',
    sumIncomeExpenses: 'สรุป รายรับ-รายจ่าย สำหรับเดือน', //Summary of income-expenses for the month
    incomeOther: 'รายรับอื่นๆ',
    grossProfit: 'กำไรสุทธิ',
    newUser: 'เพิ่มผู้ใช้',
    userType: 'ประเภทผู้ใช้',
    password: 'รหัสผ่าน',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    ParticipatingProducts: 'เลือกรายการสินค้า',
    descriptionSilomOrder:
        'เลือกรายการสินค้าสำหรับแสดงบน order.silompos.com ซึ่งลูกค้าสามารถทำการสั่งซื้อสินค้าด้วยตนเองได้',
    cashierManagement: 'จัดการแคชเชียร์',
    cashierManageButton: 'จัดการข้อมูล',
    cashierUsernamePassword: 'สร้างชื่อผู้ใช้/รหัสผ่าน',
    cashierCRUDDescription: 'สำหรับเข้าใช้หน้าเว็บ order.silompos.com',
    password: 'รหัสผ่าน',
    placeholderPassword: 'กรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร',
    resetPassword: 'รีเซ็ตรหัสผ่าน',
    oldPassword: 'รหัสผ่านเดิม',
    oldPasswordPlaceholder: 'กรอกรหัสผ่านเดิม',
    newPassword: 'รหัสผ่านใหม่',
    purchaseDetail: 'รายละเอียดการซื้อ',
    uploadImage: 'อัพโหลดรูปภาพ',
    keyword: 'กรอกคำค้นหา',
    firstname: 'ชื่อ',
    lastname: 'นามสกุล',
    province: 'จังหวัด',
    district: 'เขต/อำเภอ',
    subdistrict: 'แขวง/ตำบล',
    addressNumber: 'เลขที่/ถนน/ตึก/อาคาร',
    setAsDefaultAddress: 'เลือกเป็นที่อยู่ตั้งต้น',
    setAsTaxAddress: 'ตั้งเป็นที่อยู่สำหรับออกใบกำกับภาษี',
    setAsShippingAddress: 'ตั้งเป็นที่อยู่สำหรับจัดส่งสินค้า',
    contact: 'ช่องทางติดต่อ',
    setAsDefault: 'ตั้งเป็นค่าเริ่มต้น',
    addressLists: 'รายการที่อยู่',
    default: 'ค่าเริ่มต้น',
    taxAddress: 'ออกใบกำกับภาษี',
    shippingAddress: 'จัดส่งสินค้า',
    addAddress: 'เพิ่มที่อยู่',
    createdAt: 'สร้างเมื่อ',
    updatedAt: 'ปรับปรุงเมื่อ',
    by: 'โดย',
    readyForSale: 'พร้อมขาย',
    sold: 'ขายแล้ว',
    delete: 'ลบ',
    items: 'รายการสินค้า',
    itemDiscount: 'ส่วนลดรายการ',
    failedToSave: 'ไม่สามารถบันทึกสินค้าได้!',
    dateOfBirth: 'วันเกิด',
    menu: 'เมนู',
    dashboardMenu: 'รายงานสรุป',
    transactionMenu: 'ประวัติการขาย',
    currentBillMenu: 'บิลที่เปิดอยู่',
    reportMenu: 'รายงาน',
    orderDeviceMenu: 'เครื่อง Order',
    memberMenu: 'สมาชิก',
    inventoryMenu: 'งานคลังสินค้า',
    productMenu: 'สินค้า',
    selfOrderMenu: 'สั่งซื้อด้วยตนเอง',
    settingMenu: 'การตั้งค่า',
    salesReportByDate: 'ยอดขายสินค้าตามวัน',
    salesReportByBill: 'ยอดขายตามรายละเอียดบิล',
    salesQuantitySummaryByProductReport: 'ยอดขายตามสินค้า',
    quantitySummaryByPLUReport: 'ยอดขายสินค้าตามขนาดบรรจุ',
    nonSellingProduct: 'สินค้าที่ไม่มีการขาย',
    salesSummaryByCashierReport: 'ยอดขายแยกตามพนักงาน',
    salesSummaryByCategoryReport: 'การขายแยกตามกลุ่มสินค้า',
    paymentReport: 'การชำระเงิน',
    salesTaxReport: 'สรุปภาษีขาย',
    drawerReport: 'ปิดรอบการขาย',
    voidBillReport: 'ยกเลิกการขาย',
    timesheetReport: 'ชั่วโมงการทำงาน',
    stockInByListReport: 'รับสินค้าเข้าแสดงรายการ',
    stockOutByListReport: 'จ่ายสินค้าออกแสดงรายการ',
    nonAdjustStockProductReport: 'สินค้าที่ยังไม่ปรับปรุงสต๊อก',
    exportProductReport: 'ส่งออกรายการสินค้า',
    salesSummaryByOrderReport: 'ยอดขายสินค้า',
    salesSummaryProductByOrderReport: 'สินค้าขายได้',
    voidBillByOrderReport: 'ยกเลิกการขายสินค้า',
    inventoryReport: 'สินค้าคงเหลือตาม SKU',
    inventoryReportByPLU: 'สินค้าคงเหลือตามขนาดบรรจุ',
    inventoryReportBySerial: 'สินค้ามี Serial No.',
    stockMovement: 'ความเคลื่อนไหวสินค้า',
    stockInDocument: 'เอกสารรับสินค้าเข้า',
    stockOutDocument: 'เอกสารจ่ายสินค้าออก',
    adjustStockDocument: 'เอกสารปรับปรุงสต๊อก',
    selfOrder: 'ช่องทางการขาย',
    cashier: 'พนักงาน',
    permission: 'สิทธิการเข้าถึง',
    menuSetting: 'ตั้งค่าเมนูสินค้า',
    report: {
        dashboard: 'รายงานสรุป',
        transaction: 'ประวัติการขาย',
        currentbill: 'บิลที่เปิดอยู่',
        daily: 'ยอดขายตามวัน',
        txtdaily: 'รายงานยอดขายสินค้าตามวัน',
        dailyDes: 'รายละเอียดยอดขายสินค้าตามวัน',
        sellbyProduct: 'การขายตามรายละเอียดบิล',
        txtsellbyProduct: 'รายงานยอดขายตามรายละเอียดบิล',
        sku: 'ยอดขายตามสินค้า',
        txtsku: 'รายงานยอดขายตามสินค้า',
        plu: 'ยอดขายสินค้าตามขนาดบรรจุ',
        textPlu: 'รายงานยอดขายสินค้าตามขนาดบรรจุ',
        paymentreport: 'การชำระเงิน',
        txtpaymentreport: 'รายงานการชำระเงิน',
        timesheet: 'ชั่วโมงการทำงาน',
        txtTimesheet: 'รายงานชั่วโมงการทำงาน ',
        unsell: 'สินค้าที่ไม่มีการขาย',
        txtUnsell: 'รายงานสินค้าที่ไม่มีการขาย',
        byCategory: 'ยอดขายตามกลุ่มสินค้า',
        byCashier: 'ยอดขายตามพนักงาน',
        salesTax: 'สรุปภาษีการขาย',
        txtSalesTax: 'รายงานสรุปภาษีขาย',
        voidbill: 'ยกเลิกการขาย',
        txtvoidbill: 'รายงานยกเลิกการขาย',
        closedSale: 'ปิดรอบการขาย',
        txtCloseSale: 'รายงานปิดรอบการขาย',
        salesByOrder: 'ยอดขาย',
        productByOrder: 'ยอดขายตามสินค้า',
        unsalesByOrder: 'ยกเลิกการขาย',
        txtbyCategory: 'รายงานการขายตามกลุ่มสินค้า ',
        txtbyCashier: 'รายงานยอดขายตามพนักงาน',
        txtDeliveryRoport: 'รายงานการชำระเงินตามรายชื่อ Delivery',
        txtCustomReport: 'รายงานการชำระเงินประเภทอื่นๆ',
        txtSalesByOrder: 'รายงานสินค้าขายได้ตามเครื่อง Order',
        txtDailyOrder: 'รายงานยอดขายสินค้าตามเครื่อง Order',
        txtViodOrder: 'รายงานยกเลิกการขายสินค้าตามเครื่อง Order',
        nonadjust: 'สินค้าที่ยังไม่ปรับปรุงสต๊อก',
        selectProductForAdjustStock: 'เลือกสินค้าเพื่อใช้ปรับปรุงสต๊อก',
        txtnonadjust: 'รายงานสินค้าที่ยังไม่ปรับปรุงสต๊อก',
        stockInReport: 'รายงานรับสินค้าเข้าแสดงรายการ',
        stockOutReport: 'รายงานจ่ายสินค้าออกแสดงรายการ',
        stockIn: 'รับสินค้าเข้าแสดงรายการ',
        stockOut: 'จ่ายสินค้าออกแสดงรายการ',
        exportProduct: 'ส่งออกรายการสินค้า',
        txtExportProduct: 'รายงานส่งออกรายการสินค้า',
        cashDrawer: 'จัดการเงินสด',
        txtCashDrawerr: 'รายงานการจัดการเงินสดประจำรอบแบบแสดงรายละเอียด',
    },
    drawer: {
        cashDrawer: 'จัดการเงินสด',
        txtCashDrawerr: 'รายงานการจัดการเงินสดประจำรอบแบบแสดงรายละเอียด',
        cashRevenue: 'รายรับ-รายจ่าย',
        txtcashRevenue: 'รายงานรายรับ-รายจ่าย',
    },
    management: {
        txtManagement: 'การจัดการ',
        user: 'บัญชีร้านค้า',
        shopBranch: 'ร้านค้าและสาขา',
        productManagement: 'การจัดการสินค้า',
        unitManagement: 'หน่วยบรรจุ',
        categorytManagement: 'กลุ่มสินค้า',
        inventory: 'งานคลังสินค้า',
        stockIn: 'รับสินค้าเข้า',
        stockOut: 'จ่ายสินค้าออก',
        adjustStock: 'ปรับปรุงสต๊อก',
        checkStock: 'Check stock',
        stockMovement: 'ความเคลื่อนไหวสินค้า',
        transferStock: 'โอนสินค้าระหว่างสาขา',
        reportByPLU: 'สินค้าคงเหลือตามขนาดบรรจุ',
        reportBySKU: 'สินค้าคงเหลือตาม SKU',
        txtreportBySKU: 'รายงานสินค้าคงเหลือตาม SKU',
        txtreportByPLU: 'รายงานสินค้าคงเหลือตามขนาดบรรจุ',
        stockInDoc: 'เอกสารรับสินค้าเข้า',
        stockOutDoc: 'เอกสารจ่ายสินค้าออก',
        adjustStockDoc: 'เอกสารปรับปรุงสต๊อก',
        transferStockDoc: 'เอกสารโอนสินค้าระหว่างสาขา',
        transferStockInDoc: 'เอกสารรับสินค้าระหว่างสาขา',
        transferStockOutDoc: 'เอกสารโอนสินค้าระหว่างสาขา',
    },
    member: {
        txtmember: 'สมาชิก',
        register: 'ลงทะเบียนร้านค้า (LINE OA)',
        loyaltyPoint: 'คะแนนสะสม',
        storeCredit: 'เครดิตสะสม',
        deal: 'ดีลสินค้า',
        giftVoucher: 'บัตรกำนัล (Voucher)',
        referral: 'แนะนำเพื่อน',
        crm: 'ระบบสมาชิก',
    },
    unableStockDescription:
        'ไม่สามารถแก้ไขสินค้าได้เนื่องจากมีการปรับปรุงสต๊อกภายหลังการสร้างเอกสาร',
    noItem: 'ไม่มีรายการ',
    enterProductName: 'กรอกชื่อสินค้า',
    ByCategory: 'ตามกลุ่มสินค้า',
    ByCategoryDetail: 'ตามรายละเอียดสินค้า',
    disabledCouponAnounce: 'ประกาศ: ขอแจ้งงดให้บริการระบบคูปองแลกรับสินค้าฟรีชั่วคราว <br>เพื่อปรับปรุงระบบให้ใช้งานได้อย่างมีประสิทธิภาพมากขึ้น อย่างไรก็ตาม คูปองส่วนลดยังสามารถใช้งานได้ตามปกติ ขอบคุณสำหรับความเข้าใจและการสนับสนุนของคุณค่ะ!',
}

export default messages
